import React from "react";
import { Container, TextField, Box, Button, createTheme, ThemeProvider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useMediaQuery } from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
  borderRadius: '14px',
  color: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    color: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-select': {
    color: '#FFFFFF',
  },
};


function CreateSpecialOffer() {
  const isDesktop = useMediaQuery('(min-width:600px)');
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  return (   
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <TextField label="Title" variant="outlined" fullWidth />
          <TextField label="Claim Code" variant="outlined" fullWidth />
          <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4} // You can set the number of rows to suit your design needs
            />         
          
          <FormControl fullWidth>
            <InputLabel>Select Type</InputLabel>
            <Select labelId="type-label" label="Select Type" fullWidth>
              <MenuItem value="">Select Type</MenuItem>
              <MenuItem value={1}>Type 1</MenuItem>
              <MenuItem value={2}>Type 2</MenuItem>
              <MenuItem value={3}>Type 3</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Discount</InputLabel>
            <Select labelId="type-label" label="Select Type" fullWidth>
              <MenuItem value="">Select Type</MenuItem>
              <MenuItem value={1}>Type 1</MenuItem>
              <MenuItem value={2}>Type 2</MenuItem>
              <MenuItem value={3}>Type 3</MenuItem>
            </Select>
          </FormControl>         
        </Box>
        <div style={{ display: "flex", marginTop: "20px", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
          >
            Submit
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default CreateSpecialOffer;
