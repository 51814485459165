import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,  
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,  
  OutlinedInput
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";



import { postRequest } from "lib/utils";
import VenueModal from "./VenueModal";


import Swal from "sweetalert2";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};


const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});



function EventCreateForm({ onSubmit, updateData, onPrev, currentData }) {
  let business_id = localStorage.getItem("business_id");
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [venue, setVenue] = useState([]);
  const [admins, setAdmins] = useState([]); 
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [eventData, setEventData] = useState(null); 
  const [remoteLogo, setremoteLogo] = useState([]);
  const [selectedCover, setselectedCover] = useState([]);    
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [venueList, setVenueList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);

   // Function to open the modal
   const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function to handle venue selection from the modal
  const handleSelectVenue = (venueId) => {
    setSelectedVenue(venueId);

    
    setOpenModal(false);
    handleCloseModal(); 
  };


  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };
    const response = await postRequest(`venue/list/`, payload);
    const data = response.data;
    //  setVenue(data.list);
    setVenueList(data.list);
  };

  const fetchAdmin = async (filterArgs = []) => {

    let venueId = localStorage.getItem("authUser");
    venueId = JSON.parse(venueId);
    venueId = venueId._id;
    const payload = {
      limit: 10000,
      page: 1,
      orgId: venueId
    };
    const response = await postRequest(`event/org/my-followers/list`, payload);
    const data = response.data;
    setAdmins(data.users);
  };

  useEffect(() => {
    eventManagment();
    fetchAdmin();
  }, []);
 

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedAdmins(
      // On autofill, we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    // updateValue("isAllowRatting", value);
  };


  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
      setselectedCover(acceptedFiles);     
    },
    accept: "image/*", // Accept only image files
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle cover image files as needed
      setremoteLogo(acceptedFiles);      
    },
    accept: "image/*", // Accept only image files
  });


  const isDesktop = useMediaQuery("(min-width:600px)"); 
   

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);   
  };

  useEffect(() => {

    if(!currentData){
      return;
    }   

    const adminIds = currentData?.admins?.map((admin) => admin?._id || admin);

    setSelectedAdmins(adminIds || []);  

    const date1 = new Date(currentData?.event_time);
    const date2 = new Date(currentData?.reservation_time);     
   

    let tempData = {
      event_time: date1 || null,
      reservation_time: date2 || null,
      venue: currentData?.venue || "",   
      admins: adminIds || []   
    }

    setEventData({...tempData});

  }, [currentData]);
 

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);  


  const getSelectedAdminNames = (selectedAdminIds) => {
    return selectedAdminIds
      .map((id) => 
        admins.find((admin) => admin._id === id)?.first_name + " " + admins.find((admin) => admin._id === id)?.last_name
      )
      .filter(name => name) // Filter out any undefined or null values
      .join(', ');
  };

  const submitData = () => {

    if(!eventData?.event_time){
      Swal.fire({
        title: 'Error!',
        text: "Please enter event time",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }    

    if(!eventData?.reservation_time){
      Swal.fire({
        title: 'Error!',
        text: "Please enter event reservation time",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    if(!eventData?.venue){
      Swal.fire({
        title: 'Error!',
        text: "Please select venue",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }   

    

    eventData.admins = selectedAdmins;
    updateData(eventData);
    onSubmit();
  }


  return (
    <>
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >   

            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Event Time"
                  value={eventData?.event_time || null} 
                  minDate={new Date().setDate(new Date().getDate() + 1)} // Setting minimum date to tomorrow
                  onChange={(date) => {
                    updateValue("event_time", date);
                    // Additional logic to reset reservation time if it's after the new event time
                    if (eventData?.reservation_time > date) {
                      updateValue("reservation_time", null);
                    }
                  }}
                  renderInput={(props) => <TextField {...props} fullWidth variant="outlined" />}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Reservation Time"
                  value={eventData?.reservation_time || null}
                  minDate={new Date().setDate(new Date().getDate())} // Setting minimum date to today
                  maxDate={eventData?.event_time || new Date().setDate(new Date().getDate() + 7)} // Max date is event time or 7 days from now
                  onChange={(date) => updateValue("reservation_time", date)}
                  renderInput={(props) => <TextField {...props} fullWidth variant="outlined" />}
                />
              </LocalizationProvider>
            </FormControl>

          </Box>        

              <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                display: "grid",
                gridTemplateColumns: isDesktop ? "1fr" : "1fr",
                gap: 2,
                "& .MuiFormControl-root": {
                  m: 1,
                  ...formElementStyle, // Applying the custom styles to each form control
                },
              }}
              >  


<FormControl fullWidth>
  {/* <InputLabel htmlFor="venue-textfield">Select Venue</InputLabel> */}
  <TextField
    id="venue-textfield"
    onChange={(e) => updateValue("venue", e.target.value)}
    value={eventData?.venue ? eventData.venue.name : "Select Venue"}
    fullWidth
    readOnly
    onClick={handleOpenModal}
    
  />
</FormControl>


{/* Render VenueModal component */}
<VenueModal
  open={openModal}
  onClose={handleCloseModal}
  venueList={venueList} // Pass venue list data to VenueModal
  onSelectVenue={(venueId) => {
    const selectedVenue = venueList.find((venue) => venue._id === venueId);
    setEventData((prevData) => ({ ...prevData, venue: selectedVenue })); // Update selected venue in the form data
    setSelectedVenue(selectedVenue);
    handleCloseModal(); // Close the modal after selecting a venue
  }}
  selectedVenue={selectedVenue}
/>



          <FormControl fullWidth>
            <InputLabel>Select Admin</InputLabel>
            <Select
              labelId="admin-multiple-label"              
              value={selectedAdmins}
              onChange={handleChange}
              input={<OutlinedInput label="Select Admin" />}
              multiple 
              renderValue={(selected) => getSelectedAdminNames(selected)}
            >
              {admins?.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {`${item.first_name} ${item.last_name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


              
        </Box>


        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
            }}
            fullWidth
            onClick={(e) => onPrev()}
            // onClick={(e) => handlerimage(e)}
          >
            Previous
          </Button>

          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
            }}
            fullWidth
            onClick={(e) => submitData()}
            // onClick={(e) => handlerimage(e)}
          >
            Next
          </Button>


        </div>

      </Container>
    </ThemeProvider>    

  </>


  );
}

export default EventCreateForm;
