import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  Avatar,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const VenueModal = ({ open, onClose, venueList, onSelectVenue }) => {
  // const [searchTerm, setSearchTerm] = useState("");
  // const handleSearch = (event) => {
  //   setSearchTerm(event.target.value);
  // };

 
  return (
    <Modal open={open} onClose={onClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Paper style={{ background: "#292F3C", maxHeight: 400, overflow: "auto" }}>
        <IconButton size="small" onClick={onClose} style={{ color: 'white', marginLeft:"90%" }}>
         <CloseIcon />
        </IconButton>
        {/* <OutlinedInput
          style={{ margin: '10px', color: 'white',backgroundColor:'#22262F', borderColor: 'white',width:"90%" }}
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <IconButton size="small" style={{ color: 'white' }}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }          
        /> */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell style={{ color: "white" }}>Name</TableCell>
                  <TableCell style={{ color: "white" }}>Image </TableCell>
                  <TableCell style={{ color: "white" }}>Delivered</TableCell>
                  {/* Add more table header columns as needed */}
                  <TableCell style={{ color: "white" }}>Seen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {venueList.map((venue) => (
                  <TableRow key={venue._id}>
                    <TableCell style={{ color: "white" }}>{`${venue.first_name} ${venue.last_name}`}</TableCell>
                    <TableCell>
                      <Avatar alt={venue.name} src={venue.image} sx={{ width: 50, height: 50 }} />
                    </TableCell>
                    {/* Add more table cells with venue details */}
                    <TableCell style={{ color: "white" }}>{venue.delivered ? 'Yes' : '-'}</TableCell>
                    <TableCell style={{ color: "white" }}>{venue.seen ? 'Yes' : '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Modal>
  );
};

export default VenueModal;
