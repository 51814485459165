

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { postRequestForm, postRequest } from "../../lib/utils";

import { useHistory } from "react-router-dom";

import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";



import "assets/css/main.css";

import "./tab-style.css";
import EventCreateForm from "./EventForm";
import EventCreateForm2 from "./EventForm2";
import EventCreateForm3 from "./EventForm3";
import EventCreateForm4 from "./EventForm4";
import Follower from "./followers";

import Swal from "sweetalert2";

function EventCreate() {
  const history = useHistory();
  const [eventData, setEventData] = useState({});
  const [eventPackages, setEventPackages] = useState([]);
  const [eventInvites, setEventInvites] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab

  

  const updateEventData = (data) => {   
    let newData = eventData;
    newData = { ...newData, ...data}  
    setEventData({...newData});
  }

  const updateEventPackages = (data) => {    
    let newData = eventPackages;
    newData = [...data]
    setEventPackages(newData);
  }

  const invitedUser = (data) => {    
    let newData = eventInvites;
    newData = [...data]
    setEventInvites(newData);   
  }
    

  const Createevent = async url => {  
    const formData = new FormData(); 
    formData.append("title", eventData.title);
    formData.append("description", eventData.description);  
    formData.append("disclaimerTitle", eventData.disclaimerTitle);  
    formData.append("disclaimerDescription", eventData.disclaimerDescription);  

    formData.append("user_type", "org");
    formData.append("type", eventData.type);
    formData.append("venue_type", eventData.venue_type);
    formData.append("venue", eventData.venue?._id);
    formData.append("reservation_time", eventData.reservation_time);
    formData.append("event_time", eventData.event_time);
    formData.append("image", url);

    eventData.admins.forEach(admin => {
      formData.append("admins[]", admin);
      
    });

    eventPackages.forEach(pkg => {
      formData.append("packages_title[]", pkg.title);     
      formData.append("packages_description[]", pkg.description);
      formData.append("packages_claimCode[]", pkg.claimCode || "");
      formData.append("packages_price[]", pkg.amount);
      formData.append("packages_discount[]", pkg.discount);
      formData.append("packages_qty[]", pkg.qty);
      formData.append("packages_leftQtyAlert[]", pkg.leftQtyAlert);
      formData.append("packages_isAllowSale[]", pkg?.isAllowSale);
      formData.append("packages_isFeatured[]", pkg.isFeatured);
    });
    
    eventInvites.forEach(invite => {
      formData.append("invite_users[]", invite);
    });

    const response = await postRequestForm("event/org/my-event/create", formData);
    // setLoading(false);

    if ((response.status == 1)) {
      Swal.fire({
        title: 'Success!',
        text: 'Event created successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/dashboard");
        }
      });
           
      const payload = {
        selectedId: response.data?._id,
        sortOrder: "desc",
      };

      const response1 = await postRequest(`event/org/my-event/list`, payload);
      const data = response1.data?.list;

      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));

  
      


    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'OK'
      });      
    }    
  };  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons">
              <button
                className={activeTab == "tab1" ? "active" : ""}
                // onClick={() => handleTabClick("tab1")}
              >
                Basic Details
              </button>
              <button
                className={activeTab == "tab2" ? "active" : ""}
                // onClick={() => handleTabClick("tab2")}
              >
                Settings
              </button>
              <button
                className={activeTab == "tab3" ? "active" : ""}
                // onClick={() => handleTabClick("tab3")}
              >
                Packages
              </button>

              <button
                className={activeTab == "tab4" ? "active" : ""}
                // onClick={() => handleTabClick("tab4")}
              >
                Send Invites
              </button>

              <button
                className={activeTab == "tab5" ? "active" : ""}
                // onClick={() => handleTabClick("tab5")}
              >
                Image
              </button>


            </div>
            <div className="tab-content"> 
              {activeTab === "tab1" && <EventCreateForm onSubmit={() => setActiveTab("tab2")} updateData={ (data) => updateEventData(data)} currentData={eventData}  />}
              {activeTab === "tab2" && <EventCreateForm2 onPrev={() => setActiveTab("tab1")} onSubmit={() => setActiveTab("tab3")} updateData={ (data) => updateEventData(data)} currentData={eventData}/>}
              {activeTab === "tab3" && <EventCreateForm3 onPrev={() => setActiveTab("tab2")} onSubmit={() => setActiveTab("tab4")} updateData={ (data) => updateEventPackages(data)} currentData={eventPackages}/>}
              {activeTab === "tab4" && <Follower onPrev={() => setActiveTab("tab3")} onSubmit={() => setActiveTab("tab5")} updateData={ (data) => invitedUser(data)}  currentData={eventInvites}/>}
              {activeTab === "tab5" && <EventCreateForm4 onPrev={() => setActiveTab("tab4")} onSubmit={(url) => Createevent(url)} currentData={eventData}/>}             

            </div>
          </div>
        </Card>
        {/* <EventCreateForm /> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventCreate;
