import React, { useState } from "react";
import Card from "@mui/material/Card";
import { Pagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "grey",
      dark: "black",
      contrastText: "white",
    },
  },
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          ".MuiPaginationItem-previousNext": {
            color: "#A9A9A9",
            border: "0px",
          },
          ".MuiButtonBase-root": {
            color: "#A9A9A9",
          },
          ".Mui-selected": {
            border: "0px",
            backgroundColor: "grey !important",
            color: "black",
            fontWeight: "500",
          },
        },
      },
    },
  },
});

import authorsTableData from "layouts/tables/data/authorsTableData";

function Tables() {
  const { columns, rows } = authorsTableData;

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Authors table
              </VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                "& th": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={visibleRows} />
            </VuiBox>
            <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
              <ThemeProvider theme={theme}>
                <Pagination
                  count={Math.ceil(rows.length / rowsPerPage)}
                  color="primary"
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </ThemeProvider>
            </div>
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
