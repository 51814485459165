import React, { useState } from "react";

import CloseIcon from '@mui/icons-material/Close';

import { 
  Container, TextField,TextareaAutosize, Box, Button, createTheme, ThemeProvider, 
  Checkbox, FormControlLabel, RadioGroup, Radio, FormControl, 
  InputLabel, Select, MenuItem, useMediaQuery , Modal, Typography, IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
 
  borderRadius: '14px', // Border radius for form elements
  color: '#FFFFFF', // Text color for the form elements
  '& .MuiOutlinedInput-root': { // Targeting the input root of TextField and Select
    borderRadius: '14px', // Border radius for TextField and Select components
    color: '#FFFFFF', // Text color for the input content
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)', // Border color
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', // Border color on focus
    },
  },
  '& .MuiInputLabel-root': { // Targeting the label of the TextField and Select
    color: 'rgba(255, 255, 255, 0.7)', // Label color
  },
  '& .MuiInputLabel-root.Mui-focused': { // Label color when the input is focused
    color: '#FFFFFF', // Focused label color
  },
  '& .MuiSelect-select': { // Targeting the select input
    color: '#FFFFFF', // Text color for the select input
  },
 
  // Apply similar styles for Checkbox, Radio, and other components as needed
};


const CustomFileDropArea = styled('div')(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: '1 / -1',
    justifySelf: 'center',
    marginTop: theme.spacing(2),
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};
 
function FormUI() {  

  const [open, setOpen] = useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const isDesktop = useMediaQuery('(min-width:600px)');

  const { getRootProps, getInputProps } = useDropzone();


  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (   
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >
          <TextField label="First Name" variant="outlined" fullWidth />
          <TextField label="Last Name" variant="outlined" fullWidth />
          <TextField label="Email" variant="outlined" fullWidth />
          <TextField label="Phone" variant="outlined" fullWidth />
          <FormControlLabel control={<Checkbox defaultChecked />} label="Subscribe" />
          <FormControlLabel control={<Checkbox />} label="Accept terms and conditions" />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age" fullWidth>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
          <CustomFileDropArea {...getRootProps()} style={{minHeight: 300}}>
            <input {...getInputProps()} />
            Drag 'n' drop some files here, or click to select files
          </CustomFileDropArea>

          <CustomFileDropArea {...getRootProps()} style={{minHeight: 300}}>
            <input {...getInputProps()} />
            Drag 'n' drop some files here, or click to select files
          </CustomFileDropArea>
          
          <Button type="submit" variant="contained" style={{ background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }} fullWidth>
            Submit
          </Button>

          <div>
            <Button variant="contained" onClick={handleOpen} style={{ background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }} fullWidth>
              Contact Us
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Contact Us
                </Typography>
                <Box
                  component="form"
                  sx={{ mt: 2 }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="message"
                    label="Your Message"
                    name="message"
                    multiline
                    rows={4}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send Message
                  </Button>
                </Box>
              </Box>
            </Modal>
          </div>


        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default FormUI;
