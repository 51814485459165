
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

import { postRequest, deleteRequest } from "../../lib/utils";

import backImage from "assets/images/backimage.png";
import Welcome from "./components/Welcome";
import Swal from "sweetalert2";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import colors from "assets/theme/base/colors";
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Header2 from "./components/Header2";
import "assets/css/main.css";
function Dashboard() {
  let history = useHistory();
  const [businessData, setBusinessData] = useState(null);  
  const [venue, setVenue] = useState([]);
  const [displayCount, setDisplayCount] = useState(8);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    
    if (authUser) {
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);
    }
  }, []);

  const { gradients } = colors;

  

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
      sortOrder: "desc",
    };

    const response = await postRequest(`event/org/my-event/list`, payload);
    const data = response.data;

    setVenue(data.list);
  };

  const selectVenue = (venueId) => {

    const currentVenueData = venue.find((item) => item._id === venueId);    

    localStorage.setItem("venueId", venueId);
    localStorage.setItem("venueLogo", currentVenueData.image);
    localStorage.setItem("venueName", currentVenueData.title);
    localStorage.setItem("currentVenueData", JSON.stringify(currentVenueData));

    setTimeout(() => {
      history.push(`/eventdetail/${venueId}`);
    }, 500)

  };
  const showAlertInMiddle = async (venueId) => {
    // Calculate the middle of the document
    const middleOfPage = document.documentElement.scrollHeight / 2 - window.innerHeight / 2;

    // Scroll to the middle of the page
    window.scrollTo({
      top: middleOfPage,
      behavior: 'smooth', // Smooth scroll
    });

    // Wait a bit for the scrolling to finish, then open SweetAlert
    setTimeout(async() => {
      await deleteEvent(venueId)
    }, 500); // Adjust the delay as needed
  };

  const deleteEvent = async (venueId) => {
    // Swal confirm box

    const willDelete = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    });

    // Proceed only if confirmed
    if (willDelete.isConfirmed) {
        const response = await deleteRequest(`event/remove`, { "event_id": venueId });
        const data = response.data;

        if(data.status) {
            eventManagment(); // Assuming this is a function to manage events post-deletion
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            });
        }
    }
}

const buttonStyle = {
  display: 'block',
  margin: '0 auto', // Center-align the button horizontally
  padding: '10px 20px',
  backgroundColor: '#d63384', // Customize button background color
  color: 'white', // Customize button text color
  border: 'none',
  borderRadius: '10px',
  marginTop: '20px', // Margin at the top
  cursor: 'pointer',
};

const loadMoreImages = () => {
  // Increase the number of images to display by 4 when the button is clicked
  setDisplayCount(displayCount + 4);
};

  useEffect(() => {
    eventManagment();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <div
              className="main_card"
              >
                <WelcomeMark />

                <div
                  style={{
           
                    backgroundImage: `
                    url(${backImage})`, // Image first, then gradient with adjusted opacity
                    backgroundSize: "contain", // Cover for both the image and gradient
                    backgroundRepeat: "no-repeat", // Do not repeat the background
                    backgroundPosition: "center", // Center the background
                    borderRadius: "0 46px 46px 0", 
                    width:"100%",
                    display:"block",
                    height:"300px"// Apply rounded corners to the right side
                  }}
                />
              </div>
            </Grid>          

           
          </Grid>
        </VuiBox>        
        <Header2  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""}/>
        <br></br>
        <VuiBox mb={3}> 
         
          <Grid container spacing={3}>
          {venue?.slice(0, displayCount).map((item, index) => (
              <Grid
                item
                xs={12}
                md={6}
                xl={3}
                sx={({ breakpoints }) => ({
                  minHeight: "400px",
                })}
              >
                <Welcome img={item.image} title={item.title} desc={item.description || item.address} id={item._id} onDelete={(id) => showAlertInMiddle(id) } onSelect={(id) => selectVenue(id)} />
              </Grid>
            ))}

         
          </Grid>
          {displayCount < venue?.length && (
           <button style={buttonStyle} onClick={loadMoreImages}>Load More</button>
           )}

        </VuiBox>      
       
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
