import React, { useState, useEffect } from "react";

import { 
  TextField, 
  createTheme,
  ThemeProvider, 
  FormControl,
  InputLabel,
  Select,
  MenuItem, 
} from "@mui/material";
import "assets/css/main.css";

import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { postRequest, deleteRequest } from "lib/utils";
import { Pagination } from "@mui/material";
import VuiBox from "components/VuiBox";
import Table from "examples/Tables/Table";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});


function Invite() {  
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [pageSize, setPageSize ] = useState(10);
  
  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = pageSize;
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function HumanReadableDate({ isoDateString }) {
    const date = new Date(isoDateString);
    const humanReadableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return <div>{humanReadableDate}</div>;
  }
  const [follwers, setfollwers] = useState([]);

  const offerlistfollwers = async (resetFilter = false) => {   

    const payload = {      
      limit: 1000000,
      sortField: "createdAt", 
      sortOrder: sortOrder,      
      page: 1,   
    };

    if(!resetFilter){
      if(searchTerm){
        payload.search = searchTerm;
      }
    }



    const response = await postRequest(`event/org/guest/list`, payload);
    const data = response.data;    

    setfollwers(data.list);
  };

  useEffect(() => {
    offerlistfollwers();
  }, [sortOrder]);

  // filter logic 

  const handlePageSizeChange = (event) => {
    let value = event.target.value;
    setPageSize(value);   
  };

 

  const handleSortOrderChange = (event) => {
    let value = event.target.value;
    setSortOrder(value);   
  };

  // searching
  const handleSearchChange = (event) => {
    let value = event.target.value;
    setSearchTerm(value);
  };




  const deleteFunction = async (inviteId) => {
    const response = await deleteRequest("event/org/guest/delete", { inviteId: inviteId });
    if (response.status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {   

          offerlistfollwers();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");

        } else {          
          Swal.fire("Cancelled", "Your file is safe :)", "info");
        }
      });
    } else {
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };

  let columns = [
    { name: "userName", align: "center" },
    { name: "inviteBy", align: "center" },    
    { name: "extraGuest", align: "center" },
    { name: "inviteType", align: "center" },
    { name: "inviteStatus", align: "center" },
    { name: "createdAt", align: "center" },
    { name: "action", align: "center" },
  ];

  const Resetvalue = ()=>{
    setPageSize(10) 
    setSearchTerm("")
    offerlistfollwers(true);  
  }

  function Author({image, name }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox mr={2}>
          <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          
        </VuiBox>
      </VuiBox>
    );
  }

  let allRows = follwers;
  let startIndex = (currentPage - 1) * rowsPerPage;
  let endIndex = currentPage * rowsPerPage;
  let visibleRows = allRows.slice(startIndex, endIndex);

  

  let follwersdata = visibleRows.map((item) => {
    const getUserName = () => {
      const firstName = item?.user?.first_name;
      const lastName = item?.user?.last_name;
  
      if (firstName === undefined && lastName === undefined) {
        return 'Unknown User';
      } else {
        return `${firstName || ''} ${lastName || ''}`;
      }
    };

    const getinviteByName = () => {
      const firstName = item?.user?.first_name;
      const lastName = item?.user?.last_name;
  
      if (firstName === undefined && lastName === undefined) {
        return 'Unknown User';
      } else {
        return `${firstName || ''} ${lastName || ''}`;
      }
    };
    return {
      // 
      userName: <Author image={item?.user?.image} name={getUserName()} />,
      inviteBy: <Author image={item?.invitedBy?.image} name={getinviteByName()} />,
      extraGuest: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          {item.extraGuest}
        </VuiTypography>
      ),
      inviteType: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          {item.inviteType}
        </VuiTypography>
      ),
      inviteStatus: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          {item.inviteStatus}
        </VuiTypography>
      ),
      createdAt: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <HumanReadableDate isoDateString={item.createdAt} />
        </VuiTypography>
      ),
      action: (
        <>
          <Delete sx={{ color: "#DB2C56", fontSize: "18px" }} fontSize="medium" onClick={() => deleteFunction(item._id)} /> </>
      )
    };
  });

  const formElementStyle = {
    borderRadius: "14px", 
    color: "#FFFFFF", 
    "& .MuiOutlinedInput-root": {
     
      borderRadius: "14px", 
      color: "#FFFFFF",
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", 
      },
    },
    "& .MuiInputLabel-root": {      
      color: "rgba(255, 255, 255, 0.7)", 
    },
    "& .MuiInputLabel-root.Mui-focused": {      
      color: "#FFFFFF", 
    },
    "& .MuiSelect-select": {
      
      color: "#FFFFFF",
    },
   
  };


  return (
    <>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },

        }}
      >
      <ThemeProvider theme={theme}>
          <div className="searching-form">
            <div>
              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Sort Order:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-wrap">
              <FormControl className="page_size" sx={formElementStyle}>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <div className="reset-card">
                <button className="searchbtn" onClick={() =>  offerlistfollwers() }> Search</button>
                <button className="searchbtn reset-btn mx-2" onClick={()=>Resetvalue()}> Reset</button>               
              </div>
            </div>
          </div>
        </ThemeProvider>

        <Table columns={columns} rows={follwersdata} />
      </VuiBox>
      <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
        <ThemeProvider theme={theme}>
          <Pagination
            count={Math.ceil(follwers.length / rowsPerPage)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </ThemeProvider>
      </div>
    </>
  );
}

export default Invite;
