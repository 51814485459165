import { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";
import Welcome from "../profile/components/Welcome/index";

function Overview() {
 
  const [businessData, setBusinessData ] = useState(null);  
 

  useEffect(() => {

    const authUser = localStorage.getItem("authUser")

    if(authUser){      
      const bData = JSON.parse(authUser);     
      setBusinessData(bData);  
    }    
    
  }, []);

  return (
    <DashboardLayout>
      <Header  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""} />
      <VuiBox mt={5} mb={3}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            xs={12}
            xl={9}
            xxl={9}
            sx={({ breakpoints }) => ({
              minHeight: "400px",
              [breakpoints.only("xl")]: {
                gridArea: "1 / 1 / 2 / 2",
              },
            })}
          >
            <Welcome img={businessData?.cover || ""} name={businessData?.name || " Whosin User"} />
          </Grid>
         
          <Grid
            item
            xs={12}
            xl={3}
            xxl={3}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "1 / 2 / 2 / 3",
              },
            })}
          >
            <ProfileInfoCard              
              title="About"
              description={businessData?.description || "Whosin Event Organizer"}
              info={{
                fullName: businessData?.name || "Whosin User",
                mobile: businessData?.phone || "1234567890",
                email: businessData?.email  ,
                location: businessData?.address || "Dubai, UAE",
                website: businessData?.website || "www.whosin.com",
              }}
              social={[
                
              ]}
            />
          </Grid>
        </Grid>
      </VuiBox>
     
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
