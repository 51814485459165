import React, { useState } from "react";
import Card from "@mui/material/Card";
import { createTheme } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import './tab-style.css';

import All from "./table/all";
import In from "./table/in";
import Out from "./table/out";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "grey",
      dark: "black",
      contrastText: "white",
    },
  },
});

 
function Tables() {
  
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab


  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3} >

          <Card  style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>

          <div >
            <div className="tab-buttons">
              <button className={ activeTab == 'tab1' ? "active" : "" } onClick={() => handleTabClick("tab1")}>All Guests List</button>
              <button className={ activeTab == 'tab2' ? "active" : "" } onClick={() => handleTabClick("tab2")}>In</button>
              <button className={ activeTab == 'tab3' ? "active" : "" } onClick={() => handleTabClick("tab3")}>Out</button>
            </div>
            <div className="tab-content">
              {activeTab === "tab1" && (
                <All />

              )} {/* Render Table1 when tab1 is active */}
              {activeTab === "tab2" && <In/>} {/* Render Table2 when tab2 is active */}
              {activeTab === "tab3" && <Out/>} {/* Render Table3 when tab3 is active */}
            </div>
          </div>	


          </Card>	        
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
