import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

import { postRequest, deleteRequest } from "../../lib/utils";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Welcome from "./components/Welcome";
import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import colors from "assets/theme/base/colors";
import Header2 from "./components/Header2";

import "assets/css/main.css";
import Swal from "sweetalert2";
function Dashboard() {
  let history = useHistory();
  const [businessData, setBusinessData] = useState(null);  
  const [venue, setVenue] = useState([]);
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(8);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    
    if (authUser) {
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);
    }
  }, []);

  const openSlideShow = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };

    const response = await postRequest(`event/org/profile`, payload);
    const data = response.data?.data;

    setVenue(data?.galleries);
    setImages(data?.galleries);
  };


  const deleteGallery = async img  => {   

    const middleOfPage = document.documentElement.scrollHeight / 2 - window.innerHeight / 2;

    // Scroll to the middle of the page
    window.scrollTo({
      top: middleOfPage,
      behavior: 'smooth', 
    });

    // Wait a bit for the scrolling to finish, then open SweetAlert
    setTimeout(async() => {
      await deleteGallerySwal(img);
    }, 500); 

    
  }

  const deleteGallerySwal = async img  => {    

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',

    }).then((result) => {
      if (result.isConfirmed) {
        deleteGalleryImage(img);
      }
    }
    )
  }

  const buttonStyle = {
    display: 'block',
    margin: '0 auto', // Center-align the button horizontally
    padding: '10px 20px',
    backgroundColor: '#d63384', // Customize button background color
    color: 'white', // Customize button text color
    border: 'none',
    borderRadius: '10px',
    marginTop: '20px', // Margin at the top
    cursor: 'pointer',
  };


  const deleteGalleryImage = async img  => {


    const payload = {
      image: img
    };


    const response = await deleteRequest(`event/org/gallery/delete`, payload);
    if(response?.status ===  1){
      eventManagment();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: response?.message || 'Something went wrong!',
      })
    }      
  };

  const loadMoreImages = () => {
    // Increase the number of images to display by 4 when the button is clicked
    setDisplayCount(displayCount + 4);
  };

  useEffect(() => {
    eventManagment();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>   
        <Header2  name={businessData?.name || "Whosin User"} email={businessData?.email || "test@mail.com"} logo={businessData?.logo || ""}/>
        <br></br>
        <VuiBox mb={3}>  
        

<Grid container spacing={3}>
        {venue?.slice(0, displayCount).map((item, index) => (
          <Grid
            item
            xs={12}
            md={6}
            xl={3}
            sx={({ breakpoints }) => ({
              minHeight: "400px",
            })}
          >
            <Welcome
              img={item}
              index={index}
              title={""}
              desc={""}
              id={item}
              onSelect={(id) => deleteGallery(id)}
              onSlide={(index) => openSlideShow(index)}
            />
          </Grid>
        ))}
      </Grid>

      {displayCount < venue?.length && (
        <button style={buttonStyle} onClick={loadMoreImages}>Load More</button>
      )}

        </VuiBox>       
       
      </VuiBox>
      <Footer />
      
      {isOpen && (      
      <Lightbox
       style={{zIndex: 1000000}}
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={() => setIsOpen(false)}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      />
     
    )}
    </DashboardLayout>
  );
}

export default Dashboard;
