

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { useHistory } from "react-router-dom";

import { putRequestForm, postRequest } from "../../lib/utils";

import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "assets/css/main.css";
import "./tab-style.css";
import Follower from "./followers";

import Swal from "sweetalert2";

function EventCreate() {
  const history = useHistory();

  const [eventData, setEventData] = useState({});  
  const [eventInvites, setEventInvites] = useState([]); 
  const [selectedUser, setSelectedUser] = useState([]);
  const [fetchData, setFetchData] = useState(false);
    

  const invitedUser = (data) => {    
    let newData = eventInvites;
    newData = [...data]
    setEventInvites(newData);   
  }  

  useEffect(() => {
    let eData = localStorage.getItem("currentVenueData");
    eData = JSON.parse(eData);    
    delete eData.pack;
    delete eData.title_fuzzy;
    delete eData.description_fuzzy;
    setEventData(eData);
  }, []);

  useEffect(() => {

    if(!eventData?.invites?.length) return;
    let uids = eventData?.invites?.map((invite) => invite?.userId);
    uids = uids || [];   
    setSelectedUser([...uids]);     

    setTimeout(() => {
      setFetchData(true);
    }, 1000);

  }, [eventData?.invites]);



  useEffect(() => {
    if(!eventData?.invites?.length) return;
    Createevent();          
  }, [eventInvites]);

  const Createevent = async () => { 
    const formData = new FormData(); 
    formData.append("eventId", eventData._id);
    eventInvites.forEach(invite => {
      formData.append("invite_users[]", invite);
    });

    const response = await putRequestForm("event/org/my-event/update", formData);
    // setLoading(false);

    if (response.status === 1) {
      Swal.fire({
        title: 'Success!',
        text: 'Event data saved successfully',
        icon: 'success',
        showConfirmButton: false, // Hide the confirm button
        timer: 3000 // Set the timer for 3 seconds (adjust as needed)
      });

      const payload = {
        selectedId: eventData._id,
        sortOrder: "desc",
      };
     
      const response = await postRequest(`event/org/my-event/list`, payload);
      const data = response.data?.list;

      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));
      
      setTimeout(() => {
        history.push("/eventdetail/" + eventData._id);  
      } , 2000); 


    } else {

      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'OK'
      });      
    }    
  };  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons">
              <span
               style={{background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }}              
              >
                Send Invites
              </span>              
            </div>
            <div className="tab-content"> 
                  {fetchData ? <Follower updateData={ (data) => invitedUser(data)} currentData={selectedUser}/> : null}
                 
             </div>
          </div>
        </Card>       
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventCreate;
