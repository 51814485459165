
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PaymentMethod from "layouts/billing/components/PaymentMethod";

function Billing() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={4}>
        <VuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} xl={12}>
              <Grid container spacing={3}>
                
                <Grid item xs={12} md={12} xl={12}>
                  <PaymentMethod />
                </Grid>
              </Grid>
            </Grid>           
          </Grid>
        </VuiBox>        
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
