

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { useHistory } from "react-router-dom";

import { putRequestForm, postRequest } from "../../lib/utils";

import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "assets/css/main.css";
import "./tab-style.css";
import EventSetting from "./eventSetting";

import Swal from "sweetalert2";


function EventCreate() {
  const history = useHistory();

  const [eventData, setEventData] = useState(null);
  

  useEffect(() => {
    // fetch data from local storage if exists
    let eData = localStorage.getItem("currentVenueData");
    eData = JSON.parse(eData);
    delete eData.pack;
    delete eData.title_fuzzy;
    delete eData.description_fuzzy;
    setEventData({...eData});   
  }, []);  

  const Createevent = async eData => { 
    const formData = new FormData(); 
    formData.append("eventId", eventData._id);
    formData.append("venue", eData.venue);
    formData.append("type", eData.type);
    formData.append("venue_type", eData.venue_type);
    formData.append("reservation_time", eData.reservation_time);
    formData.append("event_time", eData.event_time);
    eData.admins.forEach(admin => {
      formData.append("admins[]", admin);
    });

    const response = await putRequestForm("event/org/my-event/update", formData);
    // setLoading(false);
    if (response.status === 1) {
      Swal.fire({
        title: 'Success!',
        text: 'Event data saved successfully',
        icon: 'success',
        showConfirmButton: false, // Hide the confirm button
        timer: 3000 // Set the timer for 3 seconds (adjust as needed)
      });

      const payload = {
        selectedId: eventData._id,
        sortOrder: "desc",
      };
     
  
      const response = await postRequest(`event/org/my-event/list`, payload);
      const data = response.data?.list;

      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));

      // wait for 1 second
      setTimeout(() => {
        history.push("/eventdetail/" + eventData._id);  
      } , 4000); 
      
    } else {

      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'OK'
      });      
    }    
  };  

  const updateEventData = (data) => {   
    let newData = eventData;
    newData = { ...newData, ...data}  
    setEventData(newData);
  }

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons">
              <button
                className={"active"}  
                style={{ visibility: "hidden" }}              
              >
                Update Event Settings
              </button>              
            </div>
            <div className="tab-content">  
                <EventSetting onSubmit={(eventData) => Createevent(eventData)} updateData={ (data) => updateEventData(data)} currentData={eventData}/>
                
             </div>
          </div>
        </Card>        
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventCreate;
