import React, { useState, useEffect } from "react";


import {  
  TextField,
  Button,
  createTheme,
  ThemeProvider,  
  FormControl,
  InputLabel,
  Select,
  MenuItem 
} from "@mui/material";
import "assets/css/main.css";
import Swal from "sweetalert2";
import { postRequest, deleteRequest } from "lib/utils";
import { Pagination } from "@mui/material";
import VuiBox from "components/VuiBox";
import Table from "examples/Tables/Table";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

function Follower({updateData, onSubmit, onPrev, currentData}) {
  // const { columns, rows } = authorsTableData; 
  const [defaultData, setDefaultData] = useState(currentData || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const rowsPerPage = pageSize;

  let startIndex = (currentPage - 1) * rowsPerPage;
  let endIndex = startIndex + rowsPerPage;
  // const visibleRows = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function HumanReadableDate({ isoDateString }) {
    const date = new Date(isoDateString);
    const humanReadableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return <div>{humanReadableDate}</div>;
  }
  const [follwers, setfollwers] = useState([]);

  const offerlistfollwers = async (searchTerm) => {    
    const payload = {
      limit: 1000000,
      page: 1,      
      sortField: "createdAt", 
      sortOrder: sortOrder,
      search: searchTerm    
    }; 

    const response = await postRequest(`event/org/followers/list`, payload);
    const data = response?.data?.lists
    setfollwers(data?.list || []);
  };

  useEffect(() => {
    offerlistfollwers();
  }, []);

  useEffect(() => {
    offerlistfollwers();
   
  }, [pageSize,currentPage,sortOrder]);
  // filter logic

  const handlePageSizeChange = (event) => {
    let value = event.target.value;
    setPageSize(value);   
  };


  const handleSortOrderChange = (event) => {
    let value = event.target.value;
    setSortOrder(value);    
    // You can do more here if needed
  };

  

  const handleSearchChange = (event) => {
    let value = event.target.value;    
    // You can do more he
    setSearchTerm(value);
  };

  const handleCheckboxChange = (id) => {
    let newSelected = [...selected];
    if (selected.includes(id)) {
      newSelected = newSelected.filter((item) => item !== id);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const submitData = () => { 
    updateData(selected);
    onSubmit();
  }

  useEffect(() => {
    setSelected(defaultData);    
  }, [defaultData]);



  const isSelected = (id) => selected.includes(id); 


  const deleteFunction = async (followerId) => {
    const response = await deleteRequest("event/org/follow/delete", { followerId: followerId });
    if (response.status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // User clicked "Yes"
          // Perform the actual delete action


          offerlistfollwers();
          Swal.fire("Deleted!", "Your file has been deleted.", "success");




        } else {
          // User clicked "No" or closed the dialog
          Swal.fire("Cancelled", "Your file is safe :)", "info");
        }
      });
    } else {
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };

  let columns = [
    { name: "User Name", align: "left" },
    { name: "date", align: "center" },
    { name: "action", align: "center" },
  ];

  function Author({ image, name, follwers }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox mr={2}>
          <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          {/* <VuiTypography variant="caption" color="text">
            {follwers}
          </VuiTypography> */}
        </VuiBox>
      </VuiBox>
    );
  }

  let allRows = follwers;
  startIndex = (currentPage - 1) * rowsPerPage;
   endIndex = currentPage * rowsPerPage;
  let visibleRows = allRows.slice(startIndex, endIndex);

  let follwersdata = visibleRows.map((item) => {
    return {
      "User Name": <Author image={item?.user?.image} name={ `${item?.user?.first_name} ${item?.user?.last_name}` } />,
      // stars: (
      //   <VuiTypography variant="caption" color="white" fontWeight="medium">
      //     {/* {item.stars} */}
      //   </VuiTypography>
      // ),
      date: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <HumanReadableDate isoDateString={item.createdAt} />
        </VuiTypography>
      ),
      // action: (
      //   <>
      //     <Delete sx={{ color: "#DB2C56", fontSize: "18px" }} fontSize="medium" onClick={() => deleteFunction(item._id)} /> {/* Delete icon */}</>
      // )
      action: (
        <>
          <input type="checkbox" checked={isSelected(item.userId)} onChange={() => handleCheckboxChange(item.userId)} /> {/* Checkbox */}         
        </>
      )
    };
  });

  const formElementStyle = {
    borderRadius: "14px", // Border radius for form elements
    color: "#FFFFFF", // Text color for the form elements
    "& .MuiOutlinedInput-root": {
      // Targeting the input root of TextField and Select
      borderRadius: "14px", // Border radius for TextField and Select components
      color: "#FFFFFF", // Text color for the input content
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)", // Border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "white", // Border color on focus
      },
    },
    "& .MuiInputLabel-root": {
      // Targeting the label of the TextField and Select
      color: "rgba(255, 255, 255, 0.7)", // Label color
    },
    "& .MuiInputLabel-root.Mui-focused": {
      // Label color when the input is focused
      color: "#FFFFFF", // Focused label color
    },
    "& .MuiSelect-select": {
      // Targeting the select input
      color: "#FFFFFF", // Text color for the select input
    },

    // Apply similar styles for Checkbox, Radio, and other components as needed
  };

  const Resetvalue = ()=>{
    setPageSize(10)
    setSearchTerm("")
    setTimeout(() => {
      setSortOrder("asc")
    }, 1000);
  }  

  return (
    <>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },

        }}
      >
      <ThemeProvider theme={theme}>
          <div className="searching-form">
            <div>
              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Sort Order:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-wrap">
              <FormControl className="page_size" sx={formElementStyle}>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <div className="reset-card">
                <button className="searchbtn " onClick={() =>  offerlistfollwers(searchTerm) }> Search</button>
                <button className="searchbtn reset-btn mx-2" onClick={()=>Resetvalue()}> Reset</button>
                {/* <button className="searchbtn "> Add</button> */}
              </div>
            </div>
          </div>
        </ThemeProvider>

        <Table columns={columns} rows={follwersdata} />
      </VuiBox>
      <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
        <ThemeProvider theme={theme}>
          <Pagination
            count={Math.ceil(follwers.length / pageSize)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </ThemeProvider>
      </div>

      <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >

      <Button
                  type="button"
                  variant="contained"
                  style={{
                    maxWidth: "150px",
                    background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
                    fontSize: "0.875rem",
                    alignItems: "flex-end",
                    color: "white",
                    borderRadius: "4px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  fullWidth
                  onClick={(e) => onPrev()}
                  // onClick={(e) => handlerimage(e)}
                >
                  Previous
                </Button>


           <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              fontSize: "0.875rem",
              alignItems: "flex-end",
              color: "white",
              borderRadius: "4px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
            fullWidth
            onClick={(e) => submitData()}
            // onClick={(e) => handlerimage(e)}
          >
            NEXT
          </Button>
        </div>


    </>
  );
}

export default Follower;
