import React, { useState } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "./tab-style.css";
import Follow from "./table/followers";
import Review from "./table/review";
import Invite from "./table/invite";
import Chat from './chat';


function Tables() {  
  const [activeTab, setActiveTab] = useState("tab1");




  


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
            <div>
              <div className="tab-buttons">
                <button
                  className={activeTab == "tab1" ? "active" : ""}
                  onClick={() => handleTabClick("tab1")}
                >
                  Followers List
                </button>
                <button
                  className={activeTab == "tab2" ? "active" : ""}
                  onClick={() => handleTabClick("tab2")}
                >
                  Review List
                </button>
                <button
                  className={activeTab == "tab3" ? "active" : ""}
                  onClick={() => handleTabClick("tab3")}
                >
                  Invite List{" "}
                </button>
              </div>
              <div className="tab-content">
                {activeTab === "tab1" && <Follow />}
                {activeTab === "tab2" && <Review />}
                {activeTab === "tab3" && <Invite />}
              </div>
            </div>
          </Card>
        </VuiBox>
      </VuiBox> */}
       <Chat />

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
