import React, { useState } from "react";
import Card from "@mui/material/Card";
import { createTheme } from "@mui/material/styles";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import './tab-style.css';
import CreateOffer from './ui/createOffer';


 
function Tables() {  
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3} >

          <Card  style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>

          <div >
            <div className="tab-buttons">
              <button className={ activeTab == 'tab1' ? "active" : "" } onClick={() => handleTabClick("tab1")}>Create Offer</button>             
            </div>
            <div className="tab-content">
              {activeTab === "tab1" && (
               <CreateOffer />
              )}     
            </div>
          </div>	


          </Card>	        
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
