import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  Avatar,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

const VenueModal = ({ open, onClose, venueList, onSelectVenue }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Paper style={{ background: "#292F3C", maxHeight: 400, overflow: "auto" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell style={{ color: "white" }}>Name</TableCell>
                  <TableCell style={{ color: "white" }}>Logo</TableCell>
                  <TableCell style={{ color: "white" }}>Address</TableCell>
                  {/* Add more table header columns as needed */}
                  <TableCell style={{ color: "white" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {venueList.map((venue) => (
                  <TableRow key={venue._id}>
                    <TableCell style={{ color: "white" }}>{venue.name}</TableCell>
                    <TableCell>
                      <Avatar alt={venue.name} src={venue.logo} sx={{ width: 50, height: 50 }} />
                    </TableCell>
                    {/* Add more table cells with venue details */}
                    <TableCell style={{ color: "white" }}>{venue.address}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => onSelectVenue(venue._id)}
                        startIcon={<AddIcon style={{ backgroundColor: "#FF25A8" }} />} // Use plus icon as the button icon
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </Modal>
  );
};

export default VenueModal;
