

import React, { useState, useEffect } from "react";

import CountUp from 'react-countup';
// @mui material components
import Grid from "@mui/material/Grid";
import { postRequest } from "../../lib/utils";
import { useParams } from 'react-router-dom';
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import colors from "assets/theme/base/colors";

// Data
import "assets/css/main.css";
import Hotelinfo from "./components/hotelinfo";
import { Slide } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function Eventdetail() {
  const { _id } = useParams();
  const [businessData, setBusinessData] = useState(null);
  const [images, setImages] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);

  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };  

  useEffect(() => {   
    const eventId = localStorage.getItem("venueId");
    fetchVenue(eventId );    
  }, [_id]);
  useEffect(() => {   
    const eventId  = localStorage.getItem("venueId");
    fetchVenue(eventId );    
  }, []);

  const fetchVenue = async (eventId) => {

    const payload = {     
      eventId: eventId 
    };

    const response = await postRequest(`event/org/my-event/profile`, payload);
    let data = response.data;
    const analytics = data?.analytics ? data?.analytics : {};
    data = data?.event ? data?.event : {};

    const galleryList = [];
    if(data?.image){
      galleryList.unshift(data?.image);
    }
    setImages(galleryList);
    setBusinessData(data);
    setAnalyticsData(analytics);
  }
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <div className="v_card">
                <div
                  style={{                 
                    height: "300px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <Slide
                      in={true}
                      direction="right"
                      style={{
                        borderRadius: "46px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        backgroundSize: "contain",
                        background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)",
                        backgroundPosition: "center",
                        height: "300px",
                      }}
                      mountOnEnter
                      unmountOnExit
                    >
                     <img
                      src={images[index]}
                      alt={`Image ${index + 1}`}
                      style={{ 
                          width: "100%",                          
                          objectFit: "cover" // Use 'contain' to maintain aspect ratio without stretching
                      }}
                    />
                    </Slide>
                    <div style={{ position: "absolute", top: "50%", left: "10px" }}>
                      <ArrowBackIos onClick={handlePrev} style={{ cursor: "pointer" }} />
                    </div>
                    <div style={{ position: "absolute", top: "50%", right: "10px" }}>
                      <ArrowForwardIos onClick={handleNext} style={{ cursor: "pointer" }} />
                    </div>
                    
                  </div>
                  <div className="banner_logo">
                    <img src={businessData?.image} />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Hotelinfo
                    _id={_id}
                    name={businessData?.title || "Event Title"}
                    email={businessData?.email || "test@mail.com"}
                    address={businessData?.description || "About Us"}
                    phone={businessData?.phone || "9999999999"}
                    website={businessData?.website || "www.xyz.com"}
                  />
                </div>
              </div>
            </Grid>          
          </Grid>
        </VuiBox>       
        <br></br>
        <VuiBox mb={3}>         
        </VuiBox>       
        <div className="hotel_main_card">
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.pageView?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.pageView?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.pageView?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Pageviews</h6>
                <div className="h-percentage">  { analyticsData?.pageView?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.pageView?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color                    

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                More
                </VuiTypography>
              </div>
            </div>
          </div>


          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.invites?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.invites?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.invites?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Invites</h6>
                <div className="h-percentage">  { analyticsData?.invites?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.invites?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                   style={ analyticsData?.checkedIn?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.checkedIn?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.checkedIn?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all checkins </h6>
                <div className="h-percentage">  { analyticsData?.checkedIn?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.checkedIn?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color                    

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
          <div className="hotel_card">
            <div className="card_head">
              <div className="h-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M12.3462 14.6509C12.2114 14.6374 12.0497 14.6374 11.9014 14.6509C8.69363 14.5431 6.14624 11.9148 6.14624 8.68002C6.14624 5.37785 8.81493 2.69568 12.1306 2.69568C15.4327 2.69568 18.1149 5.37785 18.1149 8.68002C18.1014 11.9148 15.5541 14.5431 12.3462 14.6509Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.1178 5.39124C24.7326 5.39124 26.8352 7.50732 26.8352 10.1086C26.8352 12.656 24.8135 14.7317 22.2931 14.826C22.1852 14.8125 22.0639 14.8125 21.9426 14.826"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.60695 19.6244C2.34521 21.8079 2.34521 25.3662 5.60695 27.5362C9.31346 30.0161 15.3921 30.0161 19.0987 27.5362C22.3604 25.3527 22.3604 21.7944 19.0987 19.6244C15.4056 17.1579 9.32694 17.1579 5.60695 19.6244Z"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.7192 26.9566C25.6897 26.7544 26.6062 26.3635 27.361 25.784C29.4636 24.207 29.4636 21.6057 27.361 20.0288C26.6197 19.4627 25.7166 19.0853 24.7597 18.8696"
                    stroke="white"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <svg
                  style={ analyticsData?.ad?.growth ?  { transform: "rotate(180deg)"} : {} }
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="18"
                  viewBox="0 0 37 18"
                  fill="none"
                >
                  <path
                    d="M1.72685 4.53337L1.72685 13.978C1.72685 15.3433 2.83362 16.4501 4.1989 16.4501L11.389 16.4501"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                  />
                  <path
                    d="M35.5444 0.990551L27.7804 8.96466C26.8099 9.96137 25.2085 9.96137 24.238 8.96466L18.2451 2.80964C17.2747 1.81294 15.6732 1.81294 14.7028 2.80964L2.04884 15.8059"
                    stroke="#FF2B9D"
                    stroke-width="1.85404"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              <div className="h-percentage"> {analyticsData?.ad?.differencePercentage ? (<CountUp start={0}  duration={1.50} end={analyticsData?.ad?.differencePercentage}/>) : 0 }%</div>
            </div>

            <div className="card_footer">
              <div className="h-follower">
                <h6> all Ad views</h6>
                <div className="h-percentage">  { analyticsData?.ad?.currentMonth ? (<CountUp start={0}  duration={1.00} end={analyticsData?.ad?.currentMonth}/>) : 0  }  </div>
              </div>
              <div className="h-follower"></div>
              <div className="h-follower">
                <h6> this month</h6>
                <VuiTypography
                  component="a"
                  href="/analytics"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    mr: "5px",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "106px",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    padding: "12.36px", // Added padding for button appearance
                    borderRadius: "15px", // Optional: Rounded corners
                    backgroundColor: "#FF26A8", // Choose a suitable button color
                    

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                    },

                    "&:hover, &:focus": {
                      backgroundColor: "#FF25A8", // Choose a suitable hover color
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translate(6px, 0px)`, // Adjusted hover effect
                    },
                  }}
                >
                  More
                </VuiTypography>
              </div>
            </div>
          </div>
        </div>       
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Eventdetail;
