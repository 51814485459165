
import { useState } from "react";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import { useHistory } from "react-router-dom";
import VuiButton from "components/VuiButton";
import { putRequest } from "../../../../lib/utils";
import colors from "assets/theme/base/colors";

function PaymentMethod() {
  const { grey } = colors;
  let history = useHistory();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function updatePassword() {
    let data = { oldPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword };

    if(currentPassword == ""){
      Swal.fire({
        title: 'Error!',
        text: "Please fill current password",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    if(newPassword == ""){
      Swal.fire({
        title: 'Error!',
        text: "Please fill new password",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    if(confirmPassword == ""){
      Swal.fire({
        title: 'Error!',
        text: "Please fill confirm password",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }


    if(newPassword != confirmPassword){
      Swal.fire({
        title: 'Error!',
        text: "New Password and Confirm Password does not match",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }
    putRequest("event/org/password/update", data).then((data) => {
      let log = data;
      if (log.status) {
        Swal.fire({
          title: 'Success!',
          text: log.message,
          icon: 'success',
          confirmButtonText: 'OK',
          timer: 1500, 
          showConfirmButton: false,
          timerProgressBar: true,
        }); 
       
  
        setTimeout(() => {
          history.push("/dashboard");
        }, 1500);
      } else {
        Swal.fire({
          title: 'Error!',
          text: log.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    });
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
    <Card id="delete-account" style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)",width: "400px",
    height: "400px"  }}>      

      <VuiTypography variant="lg" fontWeight="bold" color="white" mb="0px">
          Update Password
        </VuiTypography>

      <VuiBox mt="24px" gap="20px" display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        
     
         <VuiInput
      sx={{ ml: 2 }} 
      fullWidth
      type="password"
      onChange={(e) => setCurrentPassword(e.target.value)}
      placeholder="Current Password"
      inputProps={{ 'aria-label': 'Current Password' }}
    />

    <VuiInput
      sx={{ ml: 2 }} 
      fullWidth
      type="password"
      onChange={(e) => setNewPassword(e.target.value)}
      placeholder="New Password"
      inputProps={{ 'aria-label': 'New Password' }}
    />

    <VuiInput
      sx={{ ml: 2 }} 
      fullWidth
      type="password"
      onChange={(e) => setConfirmPassword(e.target.value)}
      placeholder="Confirm New Password"
      inputProps={{ 'aria-label': 'Confirm New Password' }}
    />

    <VuiButton variant="contained" color="info" sx={{ ml: 2 }}
    style={{
      background: "linear-gradient(101.39deg, #DB2C56 17.78%, #FF25A8 77.89%)", 
      width: 160
    }}
    onClick={updatePassword}
    > 
      Submit
    </VuiButton>
      </VuiBox>
    </Card>
    </div>
  );
}

export default PaymentMethod;
