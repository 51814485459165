

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { useHistory } from "react-router-dom";

import { putRequestForm, postRequest } from "../../lib/utils";

import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "assets/css/main.css";
import "./tab-style.css";
import UpdatePackage from "./updatePackage";

import Swal from "sweetalert2";


function EventCreate() {
  const history = useHistory();

  const [eventData, setEventData] = useState({});
  const [eventPackages, setEventPackages] = useState([]);

  useEffect(() => {
    let eData = localStorage.getItem("currentVenueData");
    eData = JSON.parse(eData); 
    let ePackages = eData?.pack || [];
    setEventData(eData); 
    setEventPackages([...ePackages]);    
  }, []);
  

  const Createevent = async packages => {    


    const formData = new FormData(); 

    formData.append("eventId", eventData._id);

    packages?.forEach(pkg => {
      formData.append("packages_title[]", pkg.title);   
      formData.append("packages_description[]", pkg.description);  
      formData.append("packages_price[]", pkg.amount);
      // formData.append("packages_claimCode[]", pkg.claimCode || "");

      formData.append("packages_discount[]", pkg.discount);
      formData.append("packages_qty[]", pkg.qty);
      formData.append("packages_leftQtyAlert[]", pkg.leftQtyAlert);
      formData.append("packages_isAllowSale[]", pkg?.isAllowSale || false);
      formData.append("packages_isFeatured[]", pkg?.isFeatured || false);
    });

    const response = await putRequestForm("event/org/my-event/update", formData);
    // setLoading(false);
   
    if (response.status === 1) {
      Swal.fire({
        title: 'Success!',
        text: 'Event data saved successfully',
        icon: 'success',
        showConfirmButton: false, // Hide the confirm button
        timer: 3000 // Set the timer for 3 seconds (adjust as needed)
      });

      const payload = {
        selectedId: eventData._id,
        sortOrder: "desc",
      };
     
      const response = await postRequest(`event/org/my-event/list`, payload);
      const data = response.data?.list;

      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));

      // wait for 1 second
      setTimeout(() => {
        history.push("/eventdetail/" + eventData._id);  
      } , 4000);

    } else {

      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'OK'
      });      
    }    
  };  

  const updateEventPackages = (data) => {    
    let newData = eventPackages;
    newData = [...data]
    setEventPackages(newData);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons">
              <span style={{background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)" }}>
                Update Event Packages
              </span>              
            </div>
            <div className="tab-content">    



                <UpdatePackage onSubmit={(packages) => Createevent(packages)} updateData={ (data) => updateEventPackages(data)} currentData={eventPackages}/>
             </div>
          </div>
        </Card>        
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventCreate;
