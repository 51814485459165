

import { useState, useEffect } from "react";
import Swal from "sweetalert2";
// react-router-dom components

import { useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import logo from "assets/images/logo-main.webp";




import { postRequest } from "../../../lib/utils";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";



function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  let history = useHistory();
  const [username, setEmail] = useState("");
  const [password, setPass] = useState("");


  useEffect(() => {
    checkLogin();
  }, []);

  async function checkLogin() {
    let token = localStorage.getItem("token");
    let authUser = localStorage.getItem("authUser");
    if (token && authUser) {
      history.push("/dashboard");
    }
  }



 
  async function login() {
     let data = { username: username, password };
    postRequest("event/org/login", data).then((data) => {

      let log = data;
      if (log.status) {
        Swal.fire({
          title: 'Success!',
          text: log.message,
          icon: 'success',
          confirmButtonText: 'OK',
          timer: 1500, // closes after 1500ms
          showConfirmButton: false,
          timerProgressBar: true,
        });       
  
        localStorage.setItem("authUser", JSON.stringify(log.data.profile));
        localStorage.setItem("access", log.data.type);
        localStorage.setItem("token", log.data.token);
        localStorage.setItem("BLogo", log.data.profile.logo);
        localStorage.setItem("BName", log.data.profile.name);

        const type = "business";

        if(type === "business"){
          localStorage.setItem("venueId", "");
          localStorage.setItem("venueLogo", "");
          localStorage.setItem("venueName", "");
          setTimeout(() => {
            history.push("/dashboard");
          }, 1500)
        }else{
          localStorage.setItem("venueId", log.data.profile._id);
          localStorage.setItem("venueLogo", log.data.profile.logo);
          localStorage.setItem("venueName", log.data.profile.name);
          setTimeout(() => {
            history.push("/eventdetail");
          }, 1500)
        }


      } else {
        Swal.fire({
          title: 'Error!',
          text: log.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    });
  }



  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
    
     logo={logo}
      title=""
      color="white"      
    >       
      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={1.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
         
            <VuiInput 
            type="email"
            style={{
              width: "300px !important"
            }}
            onChange={(e) => setEmail(e.target.value)}
             placeholder="Your email..." 
             sx={({ typography: { size } }) => ({
              fontSize: size.sm,
            })}  />          
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={1.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>         
            <VuiInput
              type="password"
              style={{
                width: "300px !important"
              }}
              onChange={(e) => setPass(e.target.value)}
              placeholder="Your password..."
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />          
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          {/* <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} /> */}
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;<a href="/reset-password">Reset Password</a>
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1} style={{textAlign:"center"}}>
          <VuiButton style={{
              background: "linear-gradient(101.39deg, #DB2C56 17.78%, #FF25A8 77.89%)", 
              width: 160
            }} fullWidth

            onClick={login}
            
            >
            Log In
          </VuiButton>
        </VuiBox>       
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
