import React, { useState, useEffect } from "react";
import { Pagination } from "@mui/material";

import {
  
  TextField,  
  createTheme,
  ThemeProvider,  
  FormControl,
  InputLabel,
  Select,
  MenuItem, 
} from "@mui/material";
import "assets/css/main.css";
import {  Delete } from "@mui/icons-material";

import Table from "examples/Tables/Table";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import Swal from "sweetalert2";
import Rating from '@mui/material/Rating';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

import { postRequest,deleteRequest } from "lib/utils";

function Review() {
 
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");
  const [pageSize, setPageSize] = useState(10);
  
  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = 10;

  

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  function HumanReadableDate({ isoDateString }) {
    const date = new Date(isoDateString);
    const humanReadableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return <div>{humanReadableDate}</div>;
  }
  const [review, setreview] = useState([]);

  const offerlistreview = async (searchTerm) => {
    let venueId = localStorage.getItem("authUser")
    venueId = JSON.parse(venueId);
    venueId = venueId?._id;      

    const payload = {
      limit: 10000,
      page: 1,  
      sortField: "createdAt", 
      sortOrder: sortOrder,
      orgId: venueId,
      search: searchTerm     
    }; 

    const response = await postRequest(`review/list/org`, payload);
    const data = response.data;    
   setreview(data?.list);
  };

  useEffect(() => {
    offerlistreview();
  }, []);
  useEffect(() => {
    offerlistreview();
   
  }, [pageSize,currentPage,sortOrder]);
  
  // filter logic
  const handlePageSizeChange = (event) => {
    let value = event.target.value;
    setPageSize(value);       
  };


  const handleSortOrderChange = (event) => {
    let value = event.target.value;
    setSortOrder(value);    
  };

  // searching


  const handleSearchChange = (event) => {
    let value = event.target.value;
    setSearchTerm(value);
  };






  const deleteFunction = async (id) => {
    const response = await deleteRequest("review/delete", { id: id });
    if (response.status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      }).then((result) => {
        if (result.isConfirmed) {    
          offerlistreview();   
          Swal.fire("Deleted!", "Your file has been deleted.", "success");

        } else {          
          Swal.fire("Cancelled", "Your file is safe :)", "info");
        }
      });
    } else {
      Swal.fire("Error!", "Something went wrong", "error");
    }
  };

  let columns = [
    { name: "User Name", align: "left" },
     { name: "review", align: "left" },
    { name: "stars", align: "center" },
    { name: "date", align: "center" },
    { name: "action", align: "center" },
  ];

  function Author({ image, name, review }) {
    return (
      <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
        <VuiBox mr={2}>
          <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
        </VuiBox>
        <VuiBox display="flex" flexDirection="column">
          <VuiTypography variant="button" color="white" fontWeight="medium">
            {name}
          </VuiTypography>
          <VuiTypography variant="caption" color="text">
            {review}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    );
  }

  let reviewdata = review.map((item) => {
    const getUserName = () => {
      const userName = item?.userName;
     
  
      if (userName === undefined ) {
        return 'Unknown User';
      } else {
        return `${userName || ''} `;
      }
    };

    const getReview = () => {
      const userReview = item?.review;
     
  
      if (userReview === undefined ) {
        return 'Unknown User';
      } else {
        return `${userReview || ''} `;
      }
    };

    return {
      "User Name": <Author image={item.userImage} name={getUserName()} />,
      review: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {getReview()}
          </VuiTypography> 
      ),
    
      stars: (
        <Rating
          name="stars"
          value={item.stars}
          readOnly
          precision={0.5} 
          size="small" 
        />
      ),
      date: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <HumanReadableDate isoDateString={item.createdAt} />
        </VuiTypography>
      ),

   
      action: (
        <>
          <Delete sx={{color:"#DB2C56",  fontSize: "18px"}} fontSize="medium"  onClick={ () =>  deleteFunction(item._id)}/> {/* Delete icon */}</>
      ),
    };
  });


  const formElementStyle = {
    borderRadius: "14px",
    color: "#FFFFFF", 
    "& .MuiOutlinedInput-root": {
    
      borderRadius: "14px", 
      color: "#FFFFFF", 
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.23)", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputLabel-root": {      
      color: "rgba(255, 255, 255, 0.7)", 
    },
    "& .MuiInputLabel-root.Mui-focused": {      
      color: "#FFFFFF", 
    },
    "& .MuiSelect-select": {      
      color: "#FFFFFF", 
    },    
  };

  const Resetvalue = ()=>{
    setPageSize(10)
    setSearchTerm("")
    if(sortOrder === "asc"){
      offerlistreview();
    }
    else{
      setTimeout(() => {
        setSortOrder("asc")
        
      }, 1000);
    }
    
   
  }

  return (
    <>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
          <ThemeProvider theme={theme}>
          <div className="searching-form">
            <div>
              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Page Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="page_size" sx={formElementStyle}>
                <InputLabel id="demo-simple-select-label">Sort Order:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-wrap">
              <FormControl className="page_size" sx={formElementStyle}>
                <TextField
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <div className="reset-card">
                <button className="searchbtn " onClick={() =>  offerlistreview(searchTerm) }> Search</button>
                <button className="searchbtn reset-btn mx-2" onClick={()=>Resetvalue()}> Reset</button>                
              </div>
            </div>
          </div>
        </ThemeProvider>

        <Table columns={columns} rows={reviewdata} />
      </VuiBox>
      <div style={{ alignSelf: "flex-end", color: "white", margin: "7px 10px 0px auto" }}>
        <ThemeProvider theme={theme}>
          <Pagination
            count={Math.ceil(review.length / rowsPerPage)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </ThemeProvider>
      </div>
    </>
  );
}

export default Review;
