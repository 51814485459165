import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import breakpoints from "assets/theme/base/breakpoints";
import VuiBox from "components/VuiBox";
import { IoCube } from "react-icons/io5";


import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Header({ name, logo, email }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {    
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
   
    window.addEventListener("resize", handleTabsOrientation);    
    handleTabsOrientation();    
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <VuiBox position="relative"> 
    <Grid item xs={12} md={6} lg={6.5} xl={6} xxl={1.5} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent", display: "flex", justifyContent: "flex-end", marginLeft:"auto",marginRight:"2rem" }}
              >               
                <Link
                  to={{
                    pathname: `/addGallery`,
                  }}
                >
                  <Tab
                    sx={{
                      width: "50%", // Adjust as needed
                      height: "5vh", // Adjust as needed
                      background: "#FF25A8",
                      fontSize:"16px",
                      boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
                    }}
                    label="Add Gallery"
                    icon={<IoCube color="white" size="20px" />}
                  />
                </Link>
              </Tabs>
            </AppBar>
          </Grid>     
      
    </VuiBox>
  );
}

export default Header;
