

import React from "react";
import { Card } from "@mui/material";
import welcome from "assets/images/welcome-profile.png";
import VuiTypography from "components/VuiTypography/index";
import VuiBox from "components/VuiBox/index";

const Welcome = ({img, name}) => {
  
  return (
    <Card
      sx={({ breakpoints }) => ({
        background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),
        url(${img || welcome})`,
        backgroundSize: "cover",
        borderRadius: "20px",
        height: "100%",
        [breakpoints.only("xl")]: {
          gridArea: "1 / 1 / 2 / 2",
        },
      })}
    >
      <VuiBox display="flex" flexDirection="column" sx={{ height: "100%" }}>
        <VuiBox display="flex" flexDirection="column" mb="auto">
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="3px">
            Welcome back!
          </VuiTypography>
          <VuiTypography color="white" variant="button" fontWeight="regular">
            Nice to see you, {name}!
          </VuiTypography>
        </VuiBox>
        <VuiBox justifySelf="flex-end">         
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default Welcome;
