import React from 'react';
import './ComponentStyle.css';

function VenueComponent({title, logo, onClick}) {
    return (
        <div onClick={onClick} className="venue-container" style={{ cursor: "pointer"  }}>
            <div className="profile-section">
                <img src={logo} alt="profile" className="profile-pic"/>
                <div className="profile-name">{title}</div>               
            </div>    
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M19.995 17.0888L15.0996 10.5659H7.85337C6.61337 10.5659 5.99337 12.0643 6.87171 12.9426L13.5625 19.6334C14.6346 20.7055 16.3784 20.7055 17.4505 19.6334L19.995 17.0888Z" fill="white"/>
                    <path d="M23.1467 10.5659H15.0996L19.995 17.0888L24.1413 12.9426C25.0067 12.0643 24.3867 10.5659 23.1467 10.5659Z" fill="white"/>
                </svg>        
        </div>
    );
}

export default VenueComponent;