import React from "react";

import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHistory } from 'react-router-dom';

import "assets/css/main.css";
const Hotelinfo = ({name,address, _id}) => {
  const history = useHistory();
  return (
    <Card
      sx={() => ({  
        background: "transparent",  
        width: "100%",
        display: "flex",
     
      })}
    >
      <VuiBox     className="venue_card" >
        <VuiBox>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="20px">
            {name}
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="20px">
            {address}
          </VuiTypography>

        </VuiBox>

        <div >
          <button onClick={() =>  history.push(`/event/update/${_id}`)} type="button" className="editbtn"> <i className="fas fa-edit" style={{ cursor: "pointer" }}></i></button>
        </div>

      </VuiBox>     
    </Card>
  );
};

export default Hotelinfo;
