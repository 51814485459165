

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { useHistory } from "react-router-dom";
import { putRequestForm, postRequest } from "../../lib/utils";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "assets/css/main.css";


import "./tab-style.css";
import EventCreateForm from "./EventForm";
import EventCreateForm4 from "./EventForm4";
import Swal from "sweetalert2";

function EventCreate() {
  const history = useHistory();
  const [eventData, setEventData] = useState({});
  const [eventPackages, setEventPackages] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab  
  const [eventInvites, setEventInvites] = useState([]); 

  
 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
 

  useEffect(() => {
    // fetch data from local storage if exists
    let eData = localStorage.getItem("currentVenueData");
    eData = JSON.parse(eData);    
    let ePackages = eData.pack;
    delete eData.pack;
    delete eData.title_fuzzy;
    delete eData.description_fuzzy;
    setEventData(eData); 
    setEventPackages(ePackages); 
  }, []);

  const updateEventData = (data) => {    
    let newData = eventData;
    newData = { ...newData, ...data}  
    setEventData(newData);
  }

  const updateEventPackages = (data) => {   
    let newData = eventPackages;
    newData = [...data]
    setEventPackages(newData);
  }

  const Createevent = async url => {        
    const formData = new FormData(); 
    formData.append("eventId", eventData._id);
    formData.append("title", eventData.title);
    formData.append("description", eventData.description);  
    formData.append("disclaimerTitle", eventData.disclaimerTitle); 
    formData.append("disclaimerDescription", eventData.disclaimerDescription);  
    formData.append("user_type", "org");
    formData.append("type", eventData.type);
    formData.append("venue_type", eventData.venue_type);
    formData.append("venue", eventData.venue);
    formData.append("reservation_time", eventData.reservation_time);
    formData.append("event_time", eventData.event_time);
    formData.append("image", url);
    eventData.admins.forEach(admin => {
      formData.append("admins[]", admin);
    });
    eventPackages.forEach(pkg => {
      formData.append("packages_title[]", pkg.title);   

      formData.append("packages_description[]", pkg.description);  
      formData.append("packages_price[]", pkg.amount);
      formData.append("packages_claimCode[]", pkg.claimCode || "");
      formData.append("packages_discount[]", pkg.discount);
      formData.append("packages_qty[]", pkg.qty);
      formData.append("packages_isFeatured[]", pkg?.isFeatured || false);
    }); 

    eventInvites.forEach(invite => {
      formData.append("invite_users[]", invite);
    });

    const response = await putRequestForm("event/org/my-event/update", formData);
    // setLoading(false);
    if (response.status === 1) {
      Swal.fire({
        title: 'Success!',
        text: 'Event data saved successfully',
        icon: 'success',
        showConfirmButton: false, // Hide the confirm button
        timer: 3000 // Set the timer for 3 seconds (adjust as needed)
      });

      const payload = {
        selectedId: eventData._id,
        sortOrder: "desc",
      };

      const response = await postRequest(`event/org/my-event/list`, payload);
      const data = response.data?.list;

      localStorage.setItem("currentVenueData", JSON.stringify(data[0]));

      // wait for 1 second
      setTimeout(() => {
        history.push("/eventdetail/" + eventData._id);  
      } , 4000);     
      
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'OK'
      });      
    }    
  };  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>
          <div>
            <div className="tab-buttons">
              <button
                className={activeTab == "tab1" ? "active" : ""}
                onClick={() => handleTabClick("tab1")}
              >
              Basic Details
              </button>
             
              <button
                className={activeTab == "tab5" ? "active" : ""}
                onClick={() => handleTabClick("tab5")}
              >
              Image
              </button>
            </div>
            <div className="tab-content"> 
              {activeTab === "tab1" && <EventCreateForm onSubmit={() => setActiveTab("tab5")} updateData={ (data) => updateEventData(data)} currentData={eventData}  />}             
              {activeTab === "tab5" && <EventCreateForm4 onSubmit={(url) => Createevent(url)}  currentData={eventData.image}/>}             
            </div>
          </div>
        </Card>       
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventCreate;
