import * as React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

export default function UnstyledTextareaIntroduction({ label }) {
  return <TextareaAutosize aria-label="empty textarea" minRows={3} placeholder={label} />;
}

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 628px;
  height:"100px";
  font-family: IBM Plex Sans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-color:"#E4E5E6"
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" || "light" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" || "light" ? "#22262F" : "#fff"};
  border-color: ${"#55585F"};
  
  box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" || "light" ? grey[900] : grey[50]};

  &:hover {
    // border-color: ${"#FFFFFF"};
    box-shadow: 0 0 0 1px ${theme.palette.mode === "dark" || "light" ? "#FFFFFF" : "#FFFFFF"};
  }
  
 &:focus {
    border: 0.5px solid 
    border-color: ${"#FFFFFF"};
     box-shadow: 0 0 0 2px ${theme.palette.mode === "dark" || "light" ? "#FFFFFF" : "#FFFFFF"};
   }

  // firefox
  &:focus-visible {
    outline: 0;
  }

`
);
