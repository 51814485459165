

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import { postRequest } from "../../lib/utils";

import VuiBox from "components/VuiBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import colors from "assets/theme/base/colors";

import "assets/css/main.css";

import './tab-style.css';
import AddGalleryForm from "./GalleryForm";

function AddGallery() {
  const [businessData, setBusinessData] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1"); // Initialize with the default active tab
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  
  const images = [businessData?.cover];
  const [index, setIndex] = useState(0);
 

  const [venue, setVenue] = useState([]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);      
      setBusinessData(bData);
    }
  }, []);
 

  const eventManagment = async (filterArgs = []) => {
    const payload = {
      limit: 10000,
      page: 1,
    };

    const response = await postRequest(`venue/business/my-venue-list`, payload);
    const data = response.data;

    setVenue(data.list);
  };

  useEffect(() => {
    eventManagment();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
      <Card  style={{ background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)" }}>

<div >
  <div className="tab-buttons">
    <span >Add Gallery</span>  
    
  </div>
  <div className="tab-content">
    {activeTab === "tab1" && (
     <AddGalleryForm />
    )} {/* Render Table1 when tab1 is active */}
   


  </div>
</div>	


</Card>	  
        {/* <VenueCreateForm /> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddGallery;
