import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";


import {
  Container,  
  Box,
  Button,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  Typography,  
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";


import { postRequest } from "lib/utils";
import { postRequestForm } from "lib/utils";

const { useHistory } = require("react-router-dom");



const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2), 
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});


function AddGallery() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [imgPreviews, setImgPreviews] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [totalUploaded, setTotalUploaded] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  

  const isDesktop = useMediaQuery("(min-width:600px)");

 
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles)  => { 
      setIsLoading(true);   
      const uploadedFiles = await Promise.all(acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("image", file);
  
        const res = await postRequestForm("comman/doc/upload", formData);
        return res.data.url;
      }));
      setImgPreviews([...imgPreviews, ...uploadedFiles]);
      setIsLoading(false);
    },   
  });
  

  useEffect(() => {
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
  }, []);

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
    }
  }; 

 
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  

  const uploadGallery = async () => {
    // check if there is any image to upload
    if (!imgPreviews.length) {
      alert("Please upload gallery images");
      return;
    }

    const venueId = localStorage.getItem("venueId");

    const response = await postRequest("event/org/my-gallery/create", {
      venueId: venueId,
      files: imgPreviews,
    });    

    if(response.status === 1) {

      Swal.fire({
        title: "Success",
        text: "Gallery Images Uploaded Successfully",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#DB2C56",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/gallery");
        }
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#DB2C56",
      });
    }         
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
          <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            marginTop: "20px",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >


          <CustomFileDropArea
            {...getRootProps()}
            style={{ height: 250, marginTop: "12px", backgroundColor: "#2D3547", borderStyle: "dashed", borderWidth: "2px", borderColor: 'grey',borderRadius: "12px" }}
          >
             <input {...getInputProps()} multiple />
             {isLoading ? ( // Step 5: Render CircularProgress conditionally
              <CircularProgress style={{color:"red",marginTop:"80px" }} />
            ) :
             imgPreviews ?.length > 0 ? (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {imgPreviews?.map((img, index) => (
                <div key={index} style={{ marginRight: "10px" }}>
              <img src={img} alt={`Preview ${index}`} style={{ height: "100px" }} />
              </div>
            ))}
          </div>
        ) : (
              <Typography variant="body1" style={{ color: "white", display: "block", margin: "90px" }}>
              Drag 'n' drop Gallery Images here ...
            </Typography>
          )}           
          </CustomFileDropArea>{" "}   


          {totalUploaded > 0 && (
            <Typography variant="body1" style={{ display: "flex", marginTop: "20px", justifyContent: "center", color: "#fff" }}>
              Total Image Uploaded: {totalUploaded}
            </Typography>
          )}



          
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            marginTop: "20px",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >

        </Box>      





        <div style={{ display: "flex", marginTop: "20px", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="contained"
            onClick={() => history.push("/gallery")}
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
          >
            Back
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;

          <Button
            type="submit"
            variant="contained"
            // onClick={() => history.push("/gallery")}
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
            onClick={(e) => uploadGallery()}
          >
            Submit
          </Button>
        </div>

       
      </Container>
    </ThemeProvider>
  );
}

export default AddGallery;
