import React, { useState, useRef, useEffect } from 'react';
import { postRequest, postRequestForm } from "../../lib/utils";
import { TextField, Button, Box, Typography, useMediaQuery, Modal, createTheme,
  ThemeProvider   } from '@mui/material';
import Chatimage from './image1.png';
import cryptLib from "@skavinvarnan/cryptlib";
import InviteModal from "./InviteModal";
import io from 'socket.io-client';
import swal from 'sweetalert2';


const socketUrl = 'http://139.59.38.30:2096';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const Chat = () => {
  

  const [message, setMessage] = useState('');
  const [currentOrg, setCurrentOrg] = useState(null);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [eventData, setEventData] = useState(null); // Temporary storage for file data
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);

  const [userList, setUserList] = useState([]);
  const [openModal, setOpenModal] = useState(false);   
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false); // New state variable
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const currentSelectedUser = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const socket = useRef(null);

  useEffect(() => {
    socket.current = io(socketUrl);

    socket.current.on('connect', () => {
      console.log('Connected to the chat server');
    });

    // Listen for real-time messages
    socket.current.on('new_message', (newMessage) => {   
      console.log(newMessage, "newMessage")     

      if(newMessage.chatId == currentEventId){       

        if(newMessage.author == currentOrg._id) return;

        console.log(newMessage, "newMessage - rec")

        const msg = newMessage;
        
        const msgFe = {
          ...msg,
          text: base64Decode(msg.msg),
          sender: 'admin',
          time: formatTimestamp(msg.date),
        }

        if(msgFe.authorImage == "") {
          msgFe.authorImage = `https://ui-avatars.com/api/?name=${msgFe.authorName}&size=256&round=true`;
        }

        setMessages((prevMessages) => [...prevMessages, msgFe]);
      }
      
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);  


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {   
    fetchOrgDetails();
  }, []);

  useEffect(() => {
    if (currentOrg) {
      fetchMessages();
    }    
  }, [currentOrg]);

  useEffect(() => {
    if (eventData) {
      if(eventData.type === 'image' ) handleSendMessage(true); // Automatically send the message after selecting a file
      if(eventData.type === 'audio' ) handleSendMessage(false, true); // Automatically send the message after selecting a file
      
    }
  }, [eventData]);
  

  const fetchOrgDetails = async () => {
    const orgDetails = localStorage.getItem("authUser");      
    setCurrentOrg(JSON.parse(orgDetails));
    const eventId = localStorage.getItem("venueId");    
    setCurrentEventId(eventId);
  }
 

  function base64Decode(str) {
    try {
      return cryptLib.decryptCipherTextWithRandomIV(str, currentEventId);   
    } catch (error) {
      return "";
    }
  }


  function base64Encode(str) {
    try {
      return cryptLib.encryptPlainTextWithRandomIV(str, currentEventId);   
    } catch (error) {
      return "";
    }
  }
  

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  const fetchMessages = async (page = 0) => { 
    const payload = {
      chatId: currentEventId
    };   

    const response = await postRequest(`chat/messages`, payload);
    const data = response;    

    if (data.status === 1) {       

      const currentOrgId = currentOrg._id;      

      let messages = data.data.map((msg) => {
        return {
          ...msg,          
          authorImage:  msg.authorImage || `https://ui-avatars.com/api/?name=${msg.authorName}&size=256&round=true`,          
          id: msg._id,
          text: base64Decode(msg.msg),
          sender: msg.author === currentOrgId ? 'user' : 'admin',
          time: formatTimestamp(msg.date),
        };
      });

      // filter messages if text is empty then remove it

      messages = messages.filter((msg) => msg.text.trim() !== ''); 
      
      // we need to flip the messages array to show the latest message at the bottom
      messages = messages.reverse();      
      

      setMessages(messages);
    } else {
      setMessages([]);
    }
  }
  function formatDate(isoString) {
    const date = new Date(isoString);
    // Removed the timeZone option to use the local time zone
    return date.toLocaleString('en-US');
  }

  function generateRandomString() {
    // Part 1: Generate a random hexadecimal string
    let hexPart = '';
    for (let i = 0; i < 24; i++) {
      hexPart += Math.floor(Math.random() * 16).toString(16);
    }
  
    // Part 2: Generate a random number and convert it to a string with a decimal part
    let numberPart = Math.floor(Math.random() * 10000000000).toString(); // Random integer part
    let decimalPart = Math.floor(Math.random() * 1000000).toString(); // Random decimal part to ensure the length, padding with leading zeros if necessary
  
    // Combine both parts with an underscore
    return hexPart + '_' + numberPart + '.' + decimalPart.padStart(6, '0');
  }

  const fetchTimeStamps = () => {
    const date = new Date();
    return date.toLocaleString('en-US');
  };

  const formatTimestamp = (timestamp) => {
    // Ensure timestamp is treated as a number
    const date = new Date(Number(timestamp));
    return date.toLocaleString('en-US', {
      hour12: true, // Ensure AM/PM is used
    });
  };


  const handleSendMessage = (image = false, audio = false) => {
    let msg = message;
    if (message.trim() === '' && !eventData) return;

    if (eventData && image) {
      msg = eventData.data;
    }   

    if(eventData && audio) {
      msg = eventData.data;
    }

    const messageData = {
      id: generateRandomString(),
      chatId: currentEventId,
      msg: base64Encode(msg),
      type:  image ? 'image' : audio ? 'audio'  : 'text',
      chatType: 'event',
      audioDuration: "",
      author: currentOrg._id,
      authorName: currentOrg.name,
      authorImage: currentOrg.logo,
      status: 1,
      date: fetchTimeStamps(),
      receivers: [],
    }

    // emit io new_message
    socket.current.emit('new_message', JSON.stringify(messageData));

    const msgFe = {
      ...messageData,
      text: msg,
      type: image ? 'image' : audio ? 'audio' : 'text',
      sender: 'user',
      time: formatDate(new Date()),      
    }
    setMessages((prevMessages) => [...prevMessages, msgFe]);
    setMessage('');
    setEventData(null);
  };
  

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      //const fileData = URL.createObjectURL(selectedFile);
      const formData = new FormData();
      formData.append('image', selectedFile);
      const res = await postRequestForm("comman/img/upload", formData); 
      if(res.status === 1)     {
        setEventData({ type: 'image', data: res.data.url});
      }else{
         swal("Error", "Error in uploading image", "error");
      }
      
      //setFile(fileData);      
    } else if (selectedFile.type.startsWith('audio/')) {
        // Handle audio files
        const formData = new FormData();
        formData.append('audio', selectedFile);
        const res = await postRequestForm("comman/doc/upload", formData);   
        if(res.status === 1){
          setEventData({ type: 'audio', data: res.data.url});
        }else{
          swal("Error", "Error in uploading audio", "error");
        }
        
      }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOpenModal = async (message) => {
    setSelectedMessage(message);
    //setShouldOpenModal(true); // Update state to open the modal
    setOpenModal(true);

  };

  const handleCloseModal = () => {
    //setShouldOpenModal(false); // Update state to close the modal
    setOpenModal(false);
  };

  const handleMessageBoxClick = async (msg) => {
    // Open modal only if it's not an event message
    if(msg.receivers.length < 2) return;

    const ids = msg.members;
    
    const payload = {
      limit: 10000,
      page: 1,
      userIds: ids
    };
    const response = await postRequest(`user/ids`, payload);
    let data = response.data;

    data = data.map((user) => {
      return {
        ...user,
        seen: msg.seenBy.includes(user._id),
        delivered: msg.receivers.includes(user._id),
      };
    });

    setUserList(data);


    handleOpenModal(true);    
  };

  return (
    <ThemeProvider theme={theme}>
    <Box
      p={2}
      border="1px solid #ccc"
      borderRadius={5}
      boxShadow={3}
      width={isSmallScreen ? '100%' : '90%'}
      height="70vh"
      display="flex"
      flexDirection="column"
      margin="0 auto"
      marginTop={6}
    >
      <Typography variant="h4" align="center" gutterBottom style={{ color: 'white' }}>
        Event Real Time Update
      </Typography>

      <Box
        flex="1"
        minHeight={0}
        overflow="auto"
      >
        {messages.length === 0 && (
          <div style={{ marginTop: "48px" }}>
            <center><img src={Chatimage} alt="Dummy Image" style={{ height: "300px", width: "300px", borderRadius: "50%" }} /></center>
            <Typography variant="h3" align="center" style={{ color: 'white', marginTop: '50px' }}>
              Type a message to start the conversation
            </Typography>
          </div>
        )}

        {messages.map((msg) => (
            <Box key={msg.id} textAlign={msg.sender === 'user' ? 'right' : 'left'} mt={1} mb={3} style={{ position: 'relative' }}>
              
              
              {msg.sender === 'admin' && (
                <>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px', marginRight: "16px" }}>
                  <img src={msg.authorImage} alt="Avatar" style={{ height: '40px', width: '40px', borderRadius: '50%', marginRight: '10px' }} />
                  <Typography variant="body2" style={{ color: 'white' }}>{msg.authorName}</Typography>
                          <Typography                            
                        variant="body1"
                        gutterBottom
                        style={{
                          backgroundColor: msg.sender === 'user' ? '#F9279A' : '#007bff',
                          marginLeft: "16px",
                          color: 'white',
                          padding: '10px',
                          borderRadius: '10px',
                          display: 'inline-block',
                          maxWidth: '70%',
                          wordWrap: 'break-word',
                        }}
                      >

                        { msg.type === 'image' && <img src={msg.text} alt="Uploaded File" style={{ maxWidth: '100%', height: '330px', marginTop: '10px' }} />  }
                        { msg.type === 'audio' && <audio src={msg.text} controls style={{ width: '300px', marginTop: '10px' }} />    }
                        { msg.type === 'text' && msg.text  }     
                       
                      </Typography>
                </div>

                  <Typography
                  variant="caption"
                  display="block"
                  style={{ color: 'gray', marginTop: '4px' }}
                  >
                  {msg.time}
                  </Typography></>
              )}


              {msg.sender === 'user' && (
                <>
                <Typography                        
                        variant="body1"
                        gutterBottom
                        style={{
                          backgroundColor: msg.sender === 'user' ? '#F9279A' : '#007bff',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '10px',
                          display: 'inline-block',
                          marginRight: "16px",
                          maxWidth: '70%',
                          wordWrap: 'break-word',
                        }}
                      >
                        
                        { msg.type === 'image' && <img src={msg.text} alt="Uploaded File" style={{ maxWidth: '100%', height: '330px', marginTop: '10px', marginRight: "16px" }} />  }
                        { msg.type === 'audio' && <audio src={msg.text} controls style={{ width: '300px', marginTop: '10px', marginRight: "16px", }} />    }
                        { msg.type === 'text' && msg.text  }                      

                        
                      </Typography>                      


                      <Typography
                        variant="caption"
                        display="block"
                        style={{ color: 'gray', marginTop: '4px', marginRight: "16px", }}
                      >
                        {msg.time}
                      </Typography>

                      {msg.receivers.length > 0 && (<Typography
                        onClick={() => handleMessageBoxClick(msg)}
                        
                        variant="caption"
                        display="block"
                        style={{ color: 'gray', marginTop: '4px',marginRight: "16px" }}
                      >
                      <span style={{color: "#fff"}}> Delivered to {msg?.receivers?.length - 1}</span> / <span style={{color: "#fff"}}>Seen by {msg?.seenBy?.length}</span>
                      </Typography>)
                      
                      }

                      
                      
                      
                      </>                
                
              )}





              
            </Box>
          ))}

        <div ref={messagesEndRef} />
      </Box>

      <Box display="flex" alignItems="center" mt={2}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="image/*, audio/*" // Only accept image files
        />
        <Button
          variant="outlined"
          onClick={() => fileInputRef.current.click()}
          style={{ marginRight: '10px', backgroundColor: 'black', color: 'white' }}
        >
          Upload File
        </Button>

        <TextField
          placeholder="Type a message..."
          variant="outlined"
          fullWidth
          value={message}
          onChange={handleMessageChange}
          className="chat-input"
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          style={{ width: "100% !important", color: 'white' }}
          InputLabelProps={{ className: 'placeholder-text' }}
        />

        <Button
          variant="contained"
          onClick={handleSendMessage}
          style={{
            marginLeft: isSmallScreen ? 0 : 10,
            backgroundColor: '#F9279A',
            color: 'white',
            height: '50px',
            width: isSmallScreen ? '100%' : 'auto',
          }}
        >
          Send
        </Button>
      </Box>

      <Modal
        open={shouldOpenModal} // Use shouldOpenModal to control modal opening
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >          
        </Box>
      </Modal>

     


    </Box>

    <InviteModal open={openModal}
    onClose={handleCloseModal}
    venueList={userList} // Pass venue list data to VenueModal
    selectedMessage={selectedMessage} />

    </ThemeProvider>    


  );
};

export default Chat;
