

import { useMemo,useState,useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function Table({ columns, rows }) {
  const [isLoading, setIsLoading] = useState(true);
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  useEffect(() => {
    // Simulate loading data, you can replace this with your data fetching logic
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulating a 2-second data loading delay
  }, []);

  // const renderColumns = columns.map((column) => (
  //   <TableCell key={column.id}>{column.label}</TableCell>
  // ));

  // const renderRows = rows.map((row) => (
  //   <TableRow key={row.id}>
  //     {columns.map((column) => (
  //       <TableCell key={column.id}>{row[column.id]}</TableCell>
  //     ))}
  //   </TableRow>
  // ));

  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={14}
        fontWeight={fontWeightBold}
        color={"white"}
        opacity={1}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        {name.toUpperCase()}
      </VuiBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <VuiBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <VuiBox display="flex" alignItems="center" py={0.5} px={1}>
              <VuiBox mr={2}>
                <VuiAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </VuiBox>
              <VuiTypography
                color="white"
                variant="button"
                fontWeight="medium"
                sx={{ width: "max-content" }}
              >
                {row[name][1]}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        );
      } else {
        template = (
          <VuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${grey[700]}` : null}
          >
            <VuiTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </VuiTypography>
          </VuiBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });


return useMemo(
  () => (
    <TableContainer>
      <MuiTable>
        <VuiBox component="thead">
          <TableRow>{renderColumns}</TableRow>
        </VuiBox>

        {isLoading ? ( // Assuming you have an isLoading state variable
          <TableBody>
            <TableRow>
              <TableCell
                component="td"
                colSpan={renderColumns.length}
                sx={{
                  textAlign: 'center',
                 
                  backgroundColor: 'inherit !important',
                  borderBottom: 'none',
                }}
              >
                <div className="loader" style={{color:"white"}}>Loading...</div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : renderRows?.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell
                component="td"
                colSpan={renderColumns.length}
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'inherit !important',
                  borderBottom: 'none',
                }}
              >
                <VuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  sx={{
                    display: 'block',
                    width: 'max-content',
                    margin: 'auto',
                  }}
                >
                  No data available
                </VuiTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>{renderRows}</TableBody>
        )}
      </MuiTable>
    </TableContainer>
  ),
  [columns, rows, isLoading]
);

}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
