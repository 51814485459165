import React, { useState, useEffect } from "react";
import {  useTheme } from "@mui/material/styles";
import VuiBox from "components/VuiBox";

import LineChart from "examples/Charts/LineCharts/LineChart";

import { postRequest } from "../../../lib/utils";



const staticChartData = [
  {
    name: "Total Views",
    data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
  },
  {
    name: "Unique Views",
    data: [200, 230, 300, 350, 370, 420, 550, 350, 400, 500, 330, 550],
  }
];

const staticChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "10px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "10px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#56577A",
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "vertical",
      shadeIntensity: 0,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#0075FF", "#2CD9FF"],
  },
  colors: ["#0075FF", "#2CD9FF"],
};





 
function View() {
  const muiTheme = useTheme();  
  const [currentPage, setCurrentPage] = useState(1);
  
 
 
  

  const [chartData, setChartData] = useState(staticChartData);
  const [chartOptions, setChartOptions] = useState(staticChartOptions);
  const [apiLoaded, setApiLoaded] = useState(false);


  useEffect(() => {
    fetchChartData();
  }, []);

  const fetchChartData = async () => {


    const eventId =  localStorage.getItem("venueId");

    const response = await postRequest("event/org/analytics/page", { "eventId": eventId });
    
    const tempChartOptions = { ...chartOptions };
    const tempChartData = [...chartData];

    tempChartOptions.xaxis.categories = response?.data.labels;

    tempChartData[0].data = response?.data.pageViewsData;
    tempChartData[1].data = response?.data.uniqueViewsData;    

    setChartOptions(tempChartOptions);
    setChartData(tempChartData);
    setApiLoaded(true);
  }

  

  return (   
                <>
                  <VuiBox
                    sx={{
                      "& th": {
                        borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                          `${borderWidth[1]} solid ${grey[700]}`,
                      },
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                            `${borderWidth[1]} solid ${grey[700]}`,
                        },
                      },

                      height: "600px"
                      
                    }}
                  >
                    {apiLoaded && ( <LineChart
                      lineChartData={chartData}
                      lineChartOptions={chartOptions}
                    />)}
                    
                  </VuiBox>                
                </>    
  );
}
export default View;
