import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import "assets/css/main.css";
import { Button } from "@mui/material";
function Venue() {
  



  return (    
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid
            container
            spacing="18px"
   
          >
            <Grid item xs={12} lg={12} xl={12}>
            <div
                className="v_card"
        
              >

              <div
                style={{
                  borderRadius: "46px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "0px",
          
                  backgroundSize: "cover", // Adjust as needed

                  backgroundPosition: "center",
                  backgroundImage:
                    "url('https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_627,q_auto,w_1200/hotelier-images/5e/e3/60a71e9f6c4258a5e527ee065af08c5c2ba76d1ca6c28dc38ac807490a30.jpeg')",

                  height: "300px",
                }}
              >
                <div className="edting_info">
                  <Button className="editbtn" >
                    <i className="far fa-user-edit fs-white"></i>
                  </Button>
                  {/* <i className="fas fa-envelope fs-white"></i> */}
                </div>

                <div className="banner_logo">
                  <img src="https://imgcy.trivago.com/c_fill,d_dummy.jpeg,e_sharpen:60,f_auto,h_627,q_auto,w_1200/hotelier-images/5e/e3/60a71e9f6c4258a5e527ee065af08c5c2ba76d1ca6c28dc38ac807490a30.jpeg" />
                </div>
              </div>

              <div
                style={{
                  display: "flex",

                  flexDirection: "row",
                }}
              >
                {/* <Hotelinfo /> */}
              </div>
             
           

            {/* add more code  */}

            <Card
              sx={() => ({
                //     height: "540px",
                //     flex: 4,
                //     // py: "152px",
                //     // borderRadius: "46px 0 0 46px",
                background: "transparent",
                //     // paddingLeft: "150px",
                //     backgroundSize: "cover",
                //     backgroundPosition: "50%",
                width: "100%",
                display: "flex",
                // alignItems: "center",
                // paddingLeft:"5rem"
              })}
            >
              <VuiBox
          className="venue_card"
              >
                <VuiBox>
                  <VuiTypography color="white" variant="h3" fontWeight="bold" mb="20px">
                    Taj Hotels
                  </VuiTypography>
                  <VuiTypography color="text" variant="h6" fontWeight="regular" mb="20px">
                    Travel brilliantly. It's the Marriott way.
                  </VuiTypography>

                  <div className="h-details">
                    <div className="d-inline">
                      {" "}
                      <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                        <i className="fas fa-phone-alt fs-white"></i> <span> +91 9225659423 </span>
                      </VuiTypography>{" "}
                    </div>
                    <div className="d-inline">
                      {" "}
                      <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                        {/* <i className="fas fa-phone-alt fs-white"></i> */}
                        <i className="fas fa-envelope fs-white"></i> <span>testing@gmail.com </span>
                      </VuiTypography>{" "}
                    </div>
                    <div className="d-inline">
                      {" "}
                      <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
                        {/* <i className="fas fa-phone-alt fs-white"></i> */}
                        <i className="fas fa-globe"></i> <span> wwwhoteltaj.com</span>
                      </VuiTypography>{" "}
                    </div>
                  </div>
                </VuiBox>
              </VuiBox>             
            </Card>
            </div>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
     
      <Footer />
    </DashboardLayout>
  );
}

export default Venue;
