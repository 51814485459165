import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./tab-style.css";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { postRequestForm, putRequestForm } from "lib/utils";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import Swal from "sweetalert2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px",
  color: "#FFFFFF",
  "& .MuiOutlinedInput-root": {
    borderRadius: "14px",
    color: "#FFFFFF",
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#FFFFFF",
  },
  "& .MuiSelect-select": {
    color: "#FFFFFF",
  },
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),
  marginLeft: "10px",
}));

const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});


function Editprofile() {
  let business_id = localStorage.getItem("business_id");
  const [venueData, setVenueData] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [remoteLogo, setremoteLogo] = useState([]);
  const [loading, setLoading] = useState(false); // <-- Change this line
  const [selectedCover, setselectedCover] = useState([]);
  const history = useHistory();
  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
      const file = acceptedFiles[0];
      setremoteLogo(file);

      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);      
    },
    accept: "image/*", // Accept only image files
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle cover image files as needed
      const file = acceptedFiles[0];
      setselectedCover(file);      

      const reader = new FileReader();
      reader.onload = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(file);
    },

    accept: "image/*", // Accept only image files
  });

  const isDesktop = useMediaQuery("(min-width:600px)");
  
  useEffect(() => {
    const authUser = localStorage.getItem("authUser");

    if (authUser) {
      const bData = JSON.parse(authUser);     

      setBusinessData(bData);

      setVenueData({
        name: bData.name,
        phone: bData.phone,
        email: bData.email,
        website: bData.website,
        logo: bData.logo,
        cover: bData.cover,
        description: bData.description,
      });

      setLogoPreview(bData.logo);
      setCoverPreview(bData.cover);
    }
  }, []);

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);    
  };

  const Bussinesprofileupdated = async (e) => {
    e.preventDefault();

    if (!venueData.name) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter venue name",
      });

      return;
    }

    if (!venueData.description) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter venue description",
      });

      return;
    }
    if (!venueData.phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter phone number",
      });

      return;
    }

    const phoneRegex = /^(\+\d{1,3}[\s-]?)?\d{10}$/;
    if (!phoneRegex.test(venueData.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    if (!venueData.email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter email",
      });
      return;
    }
    // check email

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(venueData.email)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a valid email",
      });
      return;
    }

    if (!venueData.website) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter website",
      });

      return;
    }

    if (!remoteLogo && !logoPreview) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select venue logo",
      });

      return;
    }

    if (!selectedCover && !coverPreview) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select venue cover",
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", venueData.name);
    formData.append("description", venueData.description);
    formData.append("phone", venueData.phone);
    formData.append("email", venueData.email);
    formData.append("website", venueData.website);

    setLoading(true);

    if (remoteLogo) {
      const remoteForm = new FormData();
      remoteForm.append("image", remoteLogo);
      const response = await postRequestForm("comman/doc/upload", remoteForm);
      if (response.status === 1) {
        let data = response.data.url;        
        formData.append("logo", data);
      }
    }

    if (selectedCover) {
      const remoteForm = new FormData();
      remoteForm.append("image", selectedCover);
      const response = await postRequestForm("comman/doc/upload", remoteForm);
      if (response.status === 1) {
        let data = response.data.url;       
        formData.append("cover", data);
      }
    }

    const response = await putRequestForm("event/org/profile/update", formData);
    setLoading(false);
    if (response.status === 1) {
      let data = response.data;      

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Profile updated successfully",
      }).then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          // Redirect to the dashboard page after the popup is closed
          localStorage.setItem("authUser", JSON.stringify(data));
          localStorage.setItem("BLogo", data.logo);
          localStorage.setItem("BCover", data.cover);
          localStorage.setItem("BName", data.name);
          history.push("/dashboard");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <DashboardLayout>
      <ThemeProvider theme={theme}>
        <VuiBox py={3}>
          <VuiBox mb={3}>
            <Card
              style={{
                background: "linear-gradient(119deg, #2E374B -13.54%, #292F3C 118.09%)",
                borderRadius: "1.25rem",
                padding: "1.25rem",
              }}
            >
              <Container component="main" maxWidth="md" style={{ marginTop: 10 }}>
                <div className="tab-buttons" style={{ marginBottom: 30}}>
                  <span style={{
                    background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)"}}>Update Profile </span>
                </div>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{
                    display: "grid",
                    gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
                    gap: 2,
                    "& .MuiFormControl-root": {
                      m: 1,
                      ...formElementStyle,
                    },
                  }}
                >
                  <TextField
                    label="Name"
                    onChange={(e) => updateValue("name", e.target.value)}
                    name="name"
                    value={venueData?.name || ""}
                    variant="outlined"
                    fullWidth
                  />

                  <TextField
                    label="Phone"
                    onChange={(e) => updateValue("phone", e.target.value)}
                    name="phone"
                    variant="outlined"
                    fullWidth
                    value={venueData?.phone || ""}
                  />

                  <TextField
                    label="Email"
                    onChange={(e) => updateValue("email", e.target.value)}
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={venueData?.email || ""}
                  />
                  <TextField
                    label="Website"
                    onChange={(e) => updateValue("website", e.target.value)}
                    name="website"
                    variant="outlined"
                    fullWidth
                    value={venueData?.website || ""}
                  />
                </Box>

                <Box
                  noValidate
                  autoComplete="off"
                  sx={{
                    display: "grid",
                    marginTop: "20px",
                    gridTemplateColumns: isDesktop ? "1fr" : "1fr",
                    gap: 2,
                    "& .MuiFormControl-root": {
                      m: 1,
                      ...formElementStyle,
                    },
                  }}
                >
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    name="description"
                    value={venueData?.description || ""}
                    onChange={(e) => updateValue("description", e.target.value)}
                    rows={4}
                  />
                </Box>

                <Box
                  noValidate
                  autoComplete="off"
                  sx={{
                    display: "grid",
                    marginTop: "20px",
                    gridTemplateColumns: isDesktop ? "1fr" : "1fr",
                    gap: 2,
                    "& .MuiFormControl-root": {
                      m: 1,
                      ...formElementStyle,
                    },
                  }}
                >
                  <CustomFileDropArea
                    {...getLogoRootProps()}
                    style={{
                      height: 150,
                      width: "auto",
                      marginTop: "12px",
                      backgroundColor: "#2D3547",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "grey",
                      borderRadius: "12px",
                    }}
                  >
                    <input {...getLogoInputProps()} />
                    {logoPreview ? (
                      <img src={logoPreview} alt="Preview" style={{ height: "100%" }} />
                    ) : (
                      <span style={{ color: "white" }}>
                        Drag 'n' drop some files here, or click to select files
                      </span>
                    )}
                  </CustomFileDropArea>{" "}
                  <CustomFileDropArea
                    {...getCoverRootProps()}
                    style={{
                      height: 150,
                      marginTop: "12px",
                      backgroundColor: "#2D3547",
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "grey",
                      borderRadius: "12px",
                    }}
                  >
                    <input {...getCoverInputProps()} />
                    {coverPreview ? (
                      <img src={coverPreview} alt="Preview" style={{ height: "100%" }} />
                    ) : (
                      <span style={{ color: "white" }}>
                        Drag 'n' drop some files here, or click to select files
                      </span>
                    )}
                  </CustomFileDropArea>
                </Box>

                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    style={{
                      maxWidth: "150px",
                      background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
                      alignItems: "flex-end",
                    }}
                    fullWidth
                    onClick={(e) => Bussinesprofileupdated(e)}
                  >
                    {loading && (
                      <CircularProgress
                        size={24}
                        style={{ marginRight: "5px" }} // Adjust as necessary
                      />
                    )}
                    {!loading && "Submit"}
                  </Button>
                </div>
              </Container>
            </Card>
          </VuiBox>
        </VuiBox>
      </ThemeProvider>
    </DashboardLayout>
  );
}

export default Editprofile;
