import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Icon,
  IconButton,
  Modal,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import VuiBox from "components/VuiBox";
import Swal from "sweetalert2";
import VuiTypography from "components/VuiTypography";
import CloseIcon from "@mui/icons-material/Close";


import { postRequest } from "../../../../lib/utils";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};
const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const WelcomeMark = () => {
  const [open, setOpen] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);

  const submitContact = (e) => {
    e.preventDefault();

    if(!profileData.fullName) {
      alert("Please enter your full name");
      return;
    }

    if(!profileData.email) {
      alert("Please enter your email");
      return;
    }

    // check if email is valid

    const emailRegex = /\S+@\S+\.\S+/;
    if(!emailRegex.test(profileData.email)) {
      alert("Please enter a valid email");
      return;
    }

    if(!profileData.phone) {
      alert("Please enter your phone number");
      return;
    }

    // check if phone is valid

    const phoneRegex = /^(\+\d{1,3}[\s-]?)?\d{10}$/;
    if (!phoneRegex.test(venueData.phone)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    if(!profileData.message) {
      alert("Please enter your message");
      return;
    }
    // message will be minimum 5 characters

    if(profileData.message.length < 5) {
      alert("Please enter a valid message");
      return;
    }

    setDisableSubmit(true);
    postRequest("venue/business/premium-ac-request", profileData).then((data) => {
      setDisableSubmit(false);
      if (data.status) {
        setOpen(false);
        setProfileData({});
        Swal.fire({
          title: 'Success!',
          text: "Your request has been sent successfully",
          icon: 'success',
          confirmButtonText: 'OK',
          timer: 1500, // closes after 1500ms
          showConfirmButton: false,
          timerProgressBar: true,
        });
      }
    });
  }
  
  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    const authUserObj = JSON.parse(authUser);
    const pData = {
      fullName: authUserObj.name,
      email: authUserObj.email,
      phone: authUserObj.phone,
      message: "",
    }
    setProfileData(pData);   
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Card
      sx={() => ({
        // height: "400px",
        borderRadius: "46px 0 0 46px",
        background: "transparent",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        marginLeft: "20px",
        widows: "100%",
      })}
    >
      
      <VuiBox height="100%" display="flex" flexDirection="column" gap="30px">
        <VuiBox>
          <VuiTypography color="white" variant="h3" fontWeight="bold" mb="30px">
            Special premium account!
          </VuiTypography>
          <VuiTypography color="text" variant="h6" fontWeight="regular" mb="5px">
            With a special premium account, you can do very interesting things!
            <br />
            By purchasing this account, special features will be activated for you that <br />
            you will be amazed to see!
          </VuiTypography>
        </VuiBox>
        <VuiTypography
          onClick={handleOpen}
          component="a"
          href="#"
          variant="button"
          color="white"
          fontWeight="regular"
          sx={{
            mr: "5px",
            display: "inline-flex",
            width: "150px",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "8.74047px 9.71163px 18.45209px 0px rgba(61, 73, 100, 0.30) inset",
            padding: "19.423px 17.481px", // Added padding for button appearance
            borderRadius: "5px", // Optional: Rounded corners
            backgroundColor: "#FF26A8", // Choose a suitable button color
            "& .material-icons-round": {
              fontSize: "1.125rem",
              transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
            },
            "&:hover, &:focus": {
              backgroundColor: "#FF25A8", // Choose a suitable hover color
            },
            "&:hover .material-icons-round, &:focus .material-icons-round": {
              transform: `translate(6px, 0px)`, // Adjusted hover effect
            },
          }}
        >
          Contact admin
          <Icon sx={{ ml: "5px" }}>shopping_cart</Icon> {/* Changed icon to cart */}
        </VuiTypography>
        <div>
          <ThemeProvider theme={theme}>
            <Modal
              style={{ bottom: "25%" }}
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} style={{backgroundColor: "#22262F"}}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                  style={{backgroundColor:"#EB28A0"}}

                >
                  <CloseIcon />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: 'white' }}>
                  Contact Us
                </Typography>
                <Box component="form" sx={{ mt: 2 }} noValidate autoComplete="off">
                  <TextField
                    margin="normal"
                    value={profileData.fullName}
                    onChange={(e) => setProfileData({...profileData, fullName: e.target.value})}
                    required
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    autoFocus
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                  />
                  <TextField
                    margin="normal"
                    value={profileData.email}
                    onChange={(e) => setProfileData({...profileData, email: e.target.value})}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                  />
                   <TextField
                    margin="normal"
                    value={profileData.phone}
                    onChange={(e) => setProfileData({...profileData, phone: e.target.value})}
                    required
                    fullWidth
                    id="email"
                    label="Phone Number"
                    name="phone"
                    type="phone"
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                  />
                  <TextField
                    margin="normal"
                    value={profileData.message}
                    onChange={(e) => setProfileData({...profileData, message: e.target.value})}
                    required
                    fullWidth
                    id="message"
                    label="Your Message"
                    name="message"
                    multiline
                    rows={4}
                    InputProps={{
                      style: { color: 'white' },
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                    }}
                  />
                  <Button  style={{backgroundColor:"#EB28A0"}}disabled={disableSubmit} type="submit" onClick={submitContact} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Send Message
                  </Button>
                </Box>
              </Box>
            </Modal>
          </ThemeProvider>
        </div>
      </VuiBox>
    </Card>
  );
};

export default WelcomeMark;
