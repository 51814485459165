import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';

import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Checkbox,
  FormControlLabel,  
  Modal,
  Typography,
  IconButton,  
  TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";




import Swal from "sweetalert2"; 
import { postRequestForm } from "lib/utils";


const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};


function EventCreateForm({ onSubmit, updateData, onPrev, currentData }) {  
  const [open, setOpen] = useState(false);
  const [currentPackage, setCurrentPackage] = useState({});
  const [packages, setPackages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlerimage = async (event, page) => {
    const selectedImageForm = new FormData();
    remoteLogo.forEach((file, index) => {
      selectedImageForm.append(`image${index}`, file);
    });
    await postRequestForm("comman/doc/upload", selectedImageForm);
  };

  const pushPackage = () => {   
    if(!currentPackage.title ){
      alert("Please enter title");
      return;
    }

    if(!currentPackage.description ){
      alert("Please enter description");
      return;
    }   


    if(!currentPackage.amount ){
      alert("Please enter amount");
      return;
    }    
   
    // check amount is positive number
    if(currentPackage.amount < 1){
      alert("Please enter valid amount");
      return;
    }    

    if(currentPackage.discount ){
      // check discount is positive number

      if(currentPackage.discount < 0 || currentPackage.discount > 100){
        alert("Please enter valid discount");
        return;
      }
    } 

    if(currentPackage.qty ){
      // check qty is positive number
      if(currentPackage.qty < 0){
        alert("Please enter valid quantity");
        return;
      }
    }

    if(currentPackage.leftQtyAlert){
      // check leftQtyAlert is positive number
      if(currentPackage.leftQtyAlert < 0){
        alert("Please enter valid left quantity alert");
        return;
      }
    }

    let index = currentIndex;
    index = index + 1;
    setCurrentIndex(index);
    let tempPackage = {...currentPackage, id: index};  
    tempPackage.isFeatured = tempPackage.isFeatured ? true : false;
    tempPackage.isAllowSale = tempPackage.isAllowSale ? true : false;
    setPackages([...packages, tempPackage]);
    setCurrentPackage({});
    handleClose();
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Package added successfully",
    });
  };  

 

  const handleDelete = (index) => {
    const newPackages = packages.filter((_, i) => i !== index);    
    setPackages(newPackages);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Package deleted successfully",
    });
  };

  useEffect(() => {
    if(currentData){
      setPackages(currentData);
    }
  }, [currentData]);

  const submitData = () => {
    if(!packages.length){
      Swal.fire({
        title: 'Error!',
        text: 'Please add at least one package',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }
    updateData(packages);
    onSubmit();
  }

  return (
    <>
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
      <Button onClick={handleOpen} type="button" variant="contained"> Add Package </Button>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: "20px", marginBottom: "20px" }}>
                
               

                <TableContainer component={Paper} elevation={3} style={{ backgroundColor: '#181B1E' }}>
                <Table aria-label="brunches table">
                  <TableHead>
                    <TableRow>
                      <TableCell  style={{ color: 'white' }}> Title </TableCell>        
                      <TableCell  style={{ color: 'white' }}> Description </TableCell>     
                      {/* <TableCell  style={{ color: 'white' }} align="right">claimCode</TableCell>              */}
                      <TableCell  style={{ color: 'white' }} align="right">Amount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Discount</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Quantity</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Left Qty Alert On</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Is Allow Sell</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Is Featured</TableCell>
                      <TableCell  style={{ color: 'white' }} align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {packages.map((brunch, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ color: 'white' }}>{brunch.title || "-"}</TableCell>      
                        <TableCell style={{ color: 'white' }}>{brunch.description || "-"}</TableCell>
                        {/* <TableCell align="right" style={{ color: 'white' }}>{brunch.claimCode || "-"}</TableCell> */}
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.amount || "-"}</TableCell>               
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.discount || "-"}</TableCell>
                        
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.qty || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.leftQtyAlert || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isAllowSale ? 'Yes' : 'No' || "-"}</TableCell>
                        <TableCell align="right" style={{ color: 'white' }}>{brunch.isFeatured ? 'Yes' : 'No' || "-"}</TableCell>
                        

                        <TableCell align="right" style={{ color: 'white' }}>
                        <IconButton onClick={() => handleDelete(index)}>
                          <DeleteIcon style={{ color: 'white' }} />
                        </IconButton>
                      </TableCell> 
          
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </Box>

          <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
            }}
            fullWidth
            onClick={(e) => onPrev()}
            // onClick={(e) => handlerimage(e)}
          >
            Previous
            
          </Button>

          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
            onClick={(e) => submitData()}
            // onClick={(e) => handlerimage(e)}
          >
            Next
          </Button>
        </div>


      </Container>
    </ThemeProvider>



<ThemeProvider theme={theme}>
<Modal
  style={{ top: "5%" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style} style={{backgroundColor: "#22262F"}}>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
      style={{backgroundColor:"#EB28A0"}}
    >
      <CloseIcon />
    </IconButton>
    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: 'white' }}>
      Add Package
    </Typography>
    
    <Box component="form" sx={{ mt: 2 }} noValidate autoComplete="off">
      <TextField
        margin="normal"
        value={currentPackage.title}
        onChange={(e) => setCurrentPackage({...currentPackage, title: e.target.value})}                   
        required
        fullWidth
        id="name"
        label="Title"
        name="item"
        autoFocus
        InputLabelProps={{ shrink: true ,style: { color: 'white' }}}    
        InputProps={{
          style: { color: 'white' },
        }}
       
      />

      <TextField
        margin="normal"
        value={currentPackage.description}
        onChange={(e) =>  setCurrentPackage({...currentPackage, description: e.target.value})}                   
        required
        fullWidth
        id="name"
        multiline={true}
        rows={4}
        label="Description"
        name="item"
        autoFocus
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}   
      />
     


      <TextField
        margin="normal"
        value={currentPackage.amount}
        onChange={(e) => setCurrentPackage({...currentPackage, amount: e.target.value})}                   
        required
        fullWidth
        id="amount"
        label="Amount"
        name="amount"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}    
      />


       <TextField
        margin="normal"
        value={currentPackage.discount}
        onChange={(e) => setCurrentPackage({...currentPackage, discount: e.target.value})}                   
        required
        fullWidth
        id="discount"
        label="Discount (%)"
        name="discount"       
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}     
      />

     <FormControlLabel
      control={<Checkbox checked={currentPackage.isAllowSale} onChange={(e) => setCurrentPackage({...currentPackage, isAllowSale: e.target.checked})} style={{ color: '#EB28A0' }}  />}
      label={<span style={{ color: 'white' }}>Allow Sell</span>}
      />
      {currentPackage.isAllowSale && (
        <>
      <TextField
        margin="normal"
        value={currentPackage.qty}
        onChange={(e) => setCurrentPackage({...currentPackage, qty: e.target.value}) }
        required
        fullWidth
        id="quantity"
        label="QTY"
        name="quantity"
        type="number"
        InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
      />

      <TextField
      margin="normal"
      value={currentPackage.leftQtyAlert}
      onChange={(e) => setCurrentPackage({...currentPackage, leftQtyAlert: e.target.value}) }
      required
      fullWidth
      id="quantity"
      label="Left Qty Alert On"
      name="quantity"
      type="number"
      InputLabelProps={{ shrink: true,style: { color: 'white' }}} 
        InputProps={{
          style: { color: 'white' },
        }}      
    />
    </>
      )}



      <FormControlLabel
      control={<Checkbox checked={currentPackage.isFeatured} onChange={(e) => setCurrentPackage({...currentPackage, isFeatured: e.target.checked})} style={{ color: '#EB28A0' }} />}
      label={<span style={{ color: 'white' }}>"Featured Package"</span>}
      />



      <Button style={{backgroundColor:"#EB28A0"}} onClick={pushPackage}  type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Save
      </Button>
    </Box>
  </Box>
</Modal>
</ThemeProvider>  </>


  );
}

export default EventCreateForm;
