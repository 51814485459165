import React from "react";
import { 
  Container, TextField, Box, Button, createTheme, ThemeProvider, 
  FormControl, InputLabel, Select, MenuItem, useMediaQuery } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: 'dark',
    },
  },
  action: {
    hover: 'rgba(255, 255, 255, 0.08)',
  },
});

const formElementStyle = {
  borderRadius: '14px',
  color: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    color: '#FFFFFF',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-select': {
    color: '#FFFFFF',
  },
};

const CustomFileDropArea = styled('div')(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: theme.spacing(2),
}));



function CreateOffer() { 
 

  const isDesktop = useMediaQuery('(min-width:600px)');
  const { getRootProps, getInputProps } = useDropzone();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  return (   
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          noValidate 
          autoComplete="off"
          sx={{
            display: 'grid',
            gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
            gap: 2,
            '& .MuiFormControl-root': {
              m: 1,
              ...formElementStyle,
            },
          }}
        >
          <TextField label="Title" variant="outlined" fullWidth />         
          <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4} // You can set the number of rows to suit your design needs
            />         
          <FormControl fullWidth>
            <InputLabel>Select Category</InputLabel>
            <Select labelId="category-label" label="Select Category" fullWidth>
              <MenuItem value="">Select Category</MenuItem>
              <MenuItem value={1}>Category 1</MenuItem>
              <MenuItem value={2}>Category 2</MenuItem>
              <MenuItem value={3}>Category 3</MenuItem>
            </Select>
          </FormControl>
          
          <TextField label="Start Date" variant="outlined" type="date" fullWidth InputLabelProps={{
    shrink: true,
  }}/>
          <TextField label="Start Time" variant="outlined" type="time" fullWidth InputLabelProps={{
    shrink: true,
  }}/>
          <TextField label="End Date" variant="outlined" type="date" fullWidth InputLabelProps={{
    shrink: true,
  }} />
          <TextField label="End Time" variant="outlined" type="time" fullWidth InputLabelProps={{
    shrink: true,
  }} />
          <FormControl fullWidth>
            <InputLabel>Allow Whosin</InputLabel>
            <Select labelId="whosin-label" label="Allow Whosin" fullWidth>
              <MenuItem value="1">Yes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </Select>
          </FormControl>
          <CustomFileDropArea
            {...getRootProps()}
            style={{ height: 250, marginTop: "12px", backgroundColor: "#2D3547", borderStyle: "dashed", borderWidth: "2px", borderColor: 'grey', borderRadius: "12px" }}
          >
            <p style={{ marginTop: "80px" , color: "#fff" }}>Drag 'n' drop Menu (PDF / Image)</p>
            <input {...getInputProps()} />
          </CustomFileDropArea>
          </Box>
          {/* Enter Packages Discounts */}
          <div style={{ display: "flex", marginTop: "20px", justifyContent: "flex-end" }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
          >
            Submit
          </Button>
        </div>
         
        
      </Container>
    </ThemeProvider>
  );
}

export default CreateOffer;
