import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  TextField,
  Box,
  Button,
  createTheme,
  ThemeProvider, 
  useMediaQuery  
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";



import Swal from "sweetalert2";

const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};


const useStyles = makeStyles({
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    gridColumn: "1 / -1",
    justifySelf: "center",
    marginTop: theme.spacing(2),
  },
});




function EventCreateForm({ onSubmit, updateData, currentData }) {
  let business_id = localStorage.getItem("business_id");
  let history = useHistory();
  const [open, setOpen] = useState(false); 
  const [eventData, setEventData] = useState(currentData); 
  const [remoteLogo, setremoteLogo] = useState([]);
  const [selectedCover, setselectedCover] = useState([]);

  const [location, setLocation] = useState({ latitude: 33.6706, longitude: 36.0227 });

  const handleMapClick = (event) => {    
    setLocation({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  };

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
      setselectedCover(acceptedFiles);      
    },
    accept: "image/*", // Accept only image files
  });

  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle cover image files as needed
      setremoteLogo(acceptedFiles);      
    },
    accept: "image/*", // Accept only image files
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isDesktop = useMediaQuery("(min-width:600px)");

  const classes = useStyles();

  useEffect(() => {

    if(!currentData){
      return;
    }


    let tempData = {
      title: currentData?.title || "",
      description: currentData?.description || "",
      disclaimerTitle: currentData?.disclaimerTitle || "",
      disclaimerDescription: currentData?.disclaimerDescription || "",
      type: currentData?.type || "",
      venue_type: currentData?.venue_type || "",
    }
    setEventData(tempData);
  }, [currentData]);
  

 
  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  
  


  const submitData = () => {   

    if(!eventData?.title){
      Swal.fire({
        title: 'Error!',
        text: "Please enter event title",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    if(!eventData?.description){
      Swal.fire({
        title: 'Error!',
        text: "Please enter event description",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    if(!eventData?.type){
      Swal.fire({
        title: 'Error!',
        text: "Please select event type",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    if(!eventData?.venue_type){
      Swal.fire({
        title: 'Error!',
        text: "Please select event venue type",
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    updateData(eventData);
    onSubmit();
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >       

          <TextField
            label="Title"
            value={eventData?.title || ""}
            onChange={(e) => updateValue("title", e.target.value)}
            name="title"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />

          </Box>

          <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >  

          

          <TextField
            label="Description"
            onChange={(e) => updateValue("description", e.target.value)}
            name="description"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={eventData?.description || ""}
            fullWidth
            multiline // This prop turns the TextField into a textarea
            rows={4} // Adjust the number of rows as needed
          />

              </Box>

              <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >       

          <TextField
            label="Title"
            value={eventData?.disclaimerTitle || ""}
            onChange={(e) => updateValue("disclaimerTitle", e.target.value)}
            name="disclaimerTitle"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            fullWidth
          />

          </Box>

          <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >  

          

          <TextField
            label="Disclaimer Description"
            onChange={(e) => updateValue("disclaimerDescription", e.target.value)}
            name="disclaimerDescription"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={eventData?.disclaimerDescription || ""}
            fullWidth
            multiline // This prop turns the TextField into a textarea
            rows={4} // Adjust the number of rows as needed
          />

              </Box>

              <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                display: "grid",
                gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
                gap: 2,
                "& .MuiFormControl-root": {
                  m: 1,
                  ...formElementStyle, // Applying the custom styles to each form control
                },
              }}
              >  

          {/* <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              labelId="category-label"
              onChange={(e) => updateValue("type", e.target.value)}
              label="Select Type"
              value={eventData?.type || ""}
              name="isAllowReview"
              fullWidth
            >
              <MenuItem value="">Type</MenuItem>
              <MenuItem value={"public"}>Public</MenuItem>
              <MenuItem value={"private"}>Private</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Venue Type</InputLabel>
            <Select
              labelId="category-label"
              label="Select Venue Type"
              name="venue_type"
              value={eventData?.venue_type || ""}
              fullWidth
              onChange={(e) => updateValue("venue_type", e.target.value)}
            >
              <MenuItem>Select Venue Type</MenuItem>
              <MenuItem value={"venue"}>Venue</MenuItem>              
            </Select>
          </FormControl> */}


          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} label="Select Event Date and Time" />}
              value={eventData?.selectedDate || null}
              onChange={(date) => updateValue("selectedDate", date)}
              variant="outlined"
              fullWidth
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField {...props} label="Select Reservation Date and Time" />
              )}
              value={eventData?.selectedDate || null}
              onChange={(date) => updateValue("selectedDate", date)}
              variant="outlined"
              fullWidth
            />
          </LocalizationProvider> */}
       
        </Box>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
            }}
            fullWidth
            onClick={(e) => submitData()}
            // onClick={(e) => handlerimage(e)}
          >
            Next
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default EventCreateForm;
