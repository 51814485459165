import React, { useEffect, useState } from "react";



// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container, 
  Box,
  Button,
  createTheme,
  ThemeProvider, 
  useMediaQuery,  
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { postRequestForm } from "lib/utils";



const theme = createTheme({
  palette: {
    primary: {
      light: "white",
      main: "#292F3C",
      dark: "black",
      contrastText: "white",
      mode: "dark",
    },
  },
  action: {
    hover: "rgba(255, 255, 255, 0.08)",
  },
});

const formElementStyle = {
  borderRadius: "14px", // Border radius for form elements
  color: "#FFFFFF", // Text color for the form elements
  "& .MuiOutlinedInput-root": {
    // Targeting the input root of TextField and Select
    borderRadius: "14px", // Border radius for TextField and Select components
    color: "#FFFFFF", // Text color for the input content
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)", // Border color
    },
    "&.Mui-focused fieldset": {
      borderColor: "white", // Border color on focus
    },
  },
  "& .MuiInputLabel-root": {
    // Targeting the label of the TextField and Select
    color: "rgba(255, 255, 255, 0.7)", // Label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    // Label color when the input is focused
    color: "#FFFFFF", // Focused label color
  },
  "& .MuiSelect-select": {
    // Targeting the select input
    color: "#FFFFFF", // Text color for the select input
  },

  // Apply similar styles for Checkbox, Radio, and other components as needed
};

const CustomFileDropArea = styled("div")(({ theme }) => ({
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
  border: `1px dashed ${theme.palette.primary.main}`,
  color: theme.palette.text.primary,
  textAlign: "center",
  cursor: "pointer",
  marginBottom: theme.spacing(2),  
  marginLeft: "10px",
}));



function EventCreateForm({onSubmit, onPrev}) { 
  const [remoteLogo, setRemoteLogo] = useState("");
  const [previewImage, setpreviewImage] = useState("");
  const [selectedCover, setselectedCover] = useState("");
  const [submitEnable, setSubmitEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
 
  

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Handle logo image files as needed
     
      const file = acceptedFiles[0];
      setselectedCover(file);
  
      // Optional: Create a preview URL for the selected image
      const reader = new FileReader();
      reader.onload = () => {
        // This will set the preview image URL
        setpreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    },
    accept: "image/*", // Accept only image files
  });

  useEffect(() => {
    if (!selectedCover) return;
    // handlerimage()
    handleImageUpload();
  }, [selectedCover]);



 

  const isDesktop = useMediaQuery("(min-width:600px)"); 

  // const handlerimage = async (event, page) => {
  //   const selectedImageForm = new FormData();
  //   selectedImageForm.append(`image`, selectedCover);
  //   const selectedImageRes = await postRequestForm("comman/doc/upload", selectedImageForm);
  //   const url = selectedImageRes.data.url;
  //   setremoteLogo(url);
  //   setsubmitEnable(true);
  // };

  const handleImageUpload = async () => {
    setIsLoading(true); // Start loader
    const selectedImageForm = new FormData();
    selectedImageForm.append(`image`, selectedCover);
    const selectedImageRes = await postRequestForm("comman/doc/upload", selectedImageForm);
    const url = selectedImageRes.data.url;
    setRemoteLogo(url);
    setSubmitEnable(true);
    setIsLoading(false); // Stop loader
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer /> */}
      <Container component="main" maxWidth="md" style={{ marginTop: 30 }}>
        <Box
          noValidate
          autoComplete="off"
          sx={{
            display: "grid",
            marginTop: "20px",
            gridTemplateColumns: isDesktop ? "1fr" : "1fr",
            gap: 2,
            "& .MuiFormControl-root": {
              m: 1,
              ...formElementStyle, // Applying the custom styles to each form control
            },
          }}
        >
          <CustomFileDropArea
            {...getLogoRootProps()}
            style={{
              height: 250,
              marginTop: "12px",
              backgroundColor: "#2D3547",
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: "grey",
              borderRadius: "12px",
            }}
          >
             {/* {selectedCover ? <img src={previewImage} alt="Preview" style={{ height: '100%' }} /> :
             <span style={{ color: "white",display:"block",margin:"90px" }}>Drag 'n' drop some files here, or click to select files</span>}
            <input {...getLogoInputProps()} /> */}
             {isLoading ? ( // Show loader if isLoading is true
              // <CircularProgress />
              <CircularProgress  style={{color:"red",marginTop:"80px" }} />
            ) : selectedCover ? (
              <img src={previewImage} alt="Preview" style={{ height: "100%" }} />
            ) : (
              <span style={{ color: "white", display: "block", margin: "90px" }}>
                Drag 'n' drop some files here, or click to select files
              </span>
            )}
            <input {...getLogoInputProps()} />
          </CustomFileDropArea>{" "}         
        </Box>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-between",
          }}
        >

        <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
            }}
            fullWidth
            onClick={(e) => onPrev()}
            // onClick={(e) => handlerimage(e)}
          >
            Previous
          </Button>


          <Button
            type="button"
            variant="contained"
            style={{
              maxWidth: "150px",
              background: "linear-gradient(101deg, #DB2C56 17.78%, #FF25A8 77.89%)",
              alignItems: "flex-end",
            }}
            fullWidth
            disabled={!submitEnable}
            onClick={(e) => onSubmit(remoteLogo)}
            // onClick={(e) => handlerimage(e)}
          >
            Submit
          </Button>
        </div>


      </Container>
    </ThemeProvider>
  );
}

export default EventCreateForm;
